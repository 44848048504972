import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const DeepLinkRedirect = () => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const ticketId = queryParams.get('id')

    if (ticketId) {
      const appLink = `app.cykelmakker.dk://ticket?id=${ticketId}`
      const webLink = `https://app.cykelmakker.dk/ticket?id=${ticketId}`

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

      if (isMobile) {
        window.location.href = appLink // Redirect to the app on mobile
      } else {
        window.location.href = webLink // Redirect to a web page on desktop
      }
    }
  }, [location])

  return null
}

export default DeepLinkRedirect
