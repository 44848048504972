export default {
  setItem: (key: string, value: any) => {
    return localStorage.setItem(key, JSON.stringify(value))
  },
  getItem: (key: string) => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    } else {
      return null
    }
  },
  removeItem: (key: string) => {
    return localStorage.removeItem(key)
  },
  updateItem: (key: string, value: any) => {
    const item = localStorage.getItem(key)
    let result = value
    if (item) {
      result = { ...JSON.parse(item), ...value }
    }
    return localStorage.setItem(key, JSON.stringify(result))
  },
  clear: () => localStorage.clear(),
}

export const LOGIN_USER = 'LOGIN_USER'
export const USER_PROFILE = 'USER_PROFILE'
