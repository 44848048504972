import { Box, Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import Icon from '../Icon/Icon'

export interface FileInputProps {
  onChange: (files: FileList | null) => void
  label: string
  multiple?: boolean
  accept?: string
}
export default function FileInput({ label, multiple, accept, onChange }: FileInputProps) {
  const [selectedFiles, setSelectedFiles] = useState<FileList>()
  const roundValue = (num: number) => {
    const roundedString = num.toFixed(2)
    const rounded = Number(roundedString)
    return `${rounded} KB`
  }

  return (
    <>
      <label>
        <Stack
          alignItems={'center'}
          direction='row'
          justifyContent='space-between'
          className='boxFile'
          marginBottom={selectedFiles?.length ? 4 : 0}
        >
          <Typography variant='body2'>{label}</Typography>
          <Icon name='icon-gallery-add' />
          <input
            type='file'
            accept={accept ?? 'image/*'}
            style={{ display: 'none' }}
            multiple={multiple ?? false}
            onChange={(event) => {
              if (selectedFiles && selectedFiles?.length && event.target.files?.length) {
                const newFileList = Array.from(selectedFiles)
                const newFileList2 = Array.from(event.target.files)
                const newSelectFiles = [...newFileList, ...newFileList2]
                setSelectedFiles(newSelectFiles as unknown as FileList)
                onChange(newSelectFiles as unknown as FileList)
              } else {
                setSelectedFiles(event.target.files ?? undefined)
                onChange(event.target.files)
              }
            }}
          />
        </Stack>
      </label>
      {selectedFiles && selectedFiles.length > 0 && (
        <Grid direction='row' spacing={4} container columns={12}>
          {Array.from(selectedFiles).map((file, index) => {
            return (
              <Grid item xs={4} key={index}>
                <Box
                  className='boxPreviewFile'
                  alignItems={'center'}
                  justifyContent='space-between'
                >
                  {file.type.includes('image/') ? (
                    <img src={URL.createObjectURL(file)} alt='previewImage' />
                  ) : file.type.includes('video/') ? (
                    <video controls>
                      <source src={URL.createObjectURL(file)} type='video/mp4' />
                    </video>
                  ) : (
                    <>
                      <img src={require('../../assets/images/PDF.png')} className='file-icon' />
                      <Grid className='file-details'>
                        <Typography variant='caption' className='truncate-text'>
                          {file.name}
                        </Typography>
                        <Typography variant='caption' className='truncate-text'>
                          {roundValue((file.size ?? 0) / 1024)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <div
                    className='boxPreviewFileClose'
                    onClick={() => {
                      if (multiple) {
                        const newFileList = Array.from(selectedFiles)
                        newFileList.splice(index, 1)
                        setSelectedFiles(
                          newFileList.length ? (newFileList as unknown as FileList) : undefined,
                        )
                        onChange(newFileList as unknown as FileList)
                      } else {
                        setSelectedFiles(undefined)
                        onChange(null)
                      }
                    }}
                  >
                    <Icon name='icon-close-circle' />
                  </div>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}
