import { Box, Button, Container, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { AppBar, Icon } from '../components'
import { CaseDisplayErrorApi, CustomerApi, SystemMessageApi } from '../services/Swagger/Services'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import ErrorModal from './ErrorModal'

function Home() {
  const { t } = useTranslation()
  const [systemMessage, setSytemMessage] = useState({
    isShow: false,
    content: '',
  })
  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    caseId: 0,
  })
  const { data } = useQuery(['/api/customers/dashboard'], async () => {
    const res = await new CustomerApi().apiCustomersDashboardGet()
    return res.data
  })
  const checkSystemMessage = () => {
    new SystemMessageApi().apiSystemMessagesGetSystemMessageGet().then(({ data }) => {
      const startDate = moment(data.startDate).unix()
      const endDate = moment(data.endDate).unix()
      const today = moment().unix()
      setSytemMessage({
        content: data.content ?? '',
        isShow: today > startDate && today < endDate,
      })
    })
  }
  function checkCases() {
    new CaseDisplayErrorApi().apiCaseDisplayErrorCheckExistDisplayErrorCaseGet().then((rs) => {
      if (Number(rs.data > 0)) {
        setErrorModal({
          isVisible: true,
          caseId: rs.data,
        })
      }
    })
  }
  useEffect(() => {
    checkCases()
    checkSystemMessage()
  }, [])

  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('welcome')}!</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'sm'}>
        <Box sx={{ mt: 8 }}>
          <img src={require('../assets/images/logo.png')} width='100%' alt='logo' />
        </Box>
        {systemMessage.isShow && systemMessage.content !== '' && (
          <Box className='messageAlert'>
            <Icon name='icon-information' color='black' />
            <span>{systemMessage.content}</span>
          </Box>
        )}
        {data && !data.myUserProducts && (
          <Box sx={{ mt: 43 }}>
            <Typography variant='h3' align='center'>
              {t('getStarted')}
            </Typography>
            <Link to={'/create-bike'}>
              <Button sx={{ mt: 8 }} fullWidth variant='contained'>
                {t('registerABike')}
              </Button>
            </Link>
          </Box>
        )}
        {data && !!data.myUserProducts && data.myUserProducts > 0 && (
          <Box sx={{ mt: 43 }}>
            <Typography variant='h3' align='center'>
              {t('createTicketDesc')}
            </Typography>
            <Link to={'/create-ticket'}>
              <Button sx={{ mt: 8 }} fullWidth variant='contained'>
                {t('createATicket')}
              </Button>
            </Link>
            <Link to={'/my-tickets'}>
              <Box sx={{ mt: 15 }} className='boxItem'>
                <img src={require('../assets/images/tickets.png')} width='100%' alt='logo' />
                <Typography variant='h3' align='center'>
                  {t('myTickets')} ({data.myCases})
                </Typography>
              </Box>
            </Link>
            <Link to={'/my-bikes'}>
              <Box sx={{ my: 4 }} className='boxItem'>
                <img src={require('../assets/images/cykel.png')} width='100%' alt='logo' />
                <Typography variant='h3' align='center'>
                  {t('myBikes')} ({data.myUserProducts})
                </Typography>
              </Box>
            </Link>
          </Box>
        )}
      </Container>
      <ErrorModal
        caseId={errorModal.caseId}
        onClose={() => {
          setErrorModal({
            isVisible: false,
            caseId: 0,
          })
        }}
        open={errorModal.isVisible}
      />
    </Box>
  )
}

export default Home
