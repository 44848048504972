import React from 'react'
import ReactQuill from 'react-quill'
require('quill-mention')
import 'react-quill/dist/quill.snow.css'
import './Editor.css'

type AtProps = {
  text: string
  value: string
}

type Props = {
  value: string
  atValues?: AtProps[]
  onChange: (content: string) => void
}

export default function Editor({ value, atValues, onChange }: Props) {
  const mentionModule = {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@', '$'],
    showDenotationChar: false,
    source: function (searchTerm: any, renderList: any) {
      const values = atValues ?? []
      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].text.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
    renderItem: function (item: any) {
      return item.text
    },
  }
  return (
    <ReactQuill
      theme='snow'
      value={value}
      onChange={onChange}
      modules={{ mention: mentionModule }}
    />
  )
}
