import { Box, Button } from '@mui/material'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { PaymentProps } from './PaymentForm'
import { CustomerCaseApi, ECurrency, EPaymentMethod } from '../services/Swagger/Services'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSnackbarState } from '../redux'

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
}
const CheckoutForm = ({
  purchaseOrderId,
  purchaseOrderCode,
  currency,
  amount,
  onSuccess,
}: PaymentProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isPaying, setIsPaying] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()
    setIsPaying(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    const cardEl = elements.getElement(CardElement)
    if (!cardEl) return
    const token = await stripe.createToken(cardEl)
    await new CustomerCaseApi().apiCustomerCasesPayPurchaseOrderPut({
      purchaseOrderId: Number(purchaseOrderId),
      purchaseOrderCode: String(purchaseOrderCode),
      total: amount,
      currency: (currency ?? 'Dkk') as ECurrency,
      token: token.token?.id ?? '',
      paymentMethod: EPaymentMethod.None,
    })
    setIsPaying(false)
    dispatch(
      updateSnackbarState({
        visible: true,
        message: 'Payment successful',
        type: 'success',
      } as any),
    )
    onSuccess && onSuccess()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ py: 2 }}>
        <CardElement options={cardStyle} />
      </Box>
      <Button
        fullWidth
        variant='contained'
        sx={{ mt: 4 }}
        disabled={!stripe || isPaying}
        type='submit'
      >
        Pay
      </Button>
    </form>
  )
}

export default CheckoutForm
