import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../Swagger/Services/configuration'
// Some imports not used depending on template conditions
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../Swagger/Services/common'
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI } from '../Swagger/Services/base'
import { CustomerCaseResponse } from '../Swagger/Services/models'
/**
 * CustomerCaseApi - axios parameter creator
 * @export
 */
export const CustomerCaseApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userProductId
     * @param {any} status
     * @param {any} reason
     * @param {any} servicePlace
     * @param {string} subject
     * @param {string} description
     * @param {any} contactInfoMainContact
     * @param {number} [repairShopId]
     * @param {Array<string>} [photos]
     * @param {string} [contactInfoAddress]
     * @param {string} [contactInfoAddress2]
     * @param {string} [contactInfoCity]
     * @param {string} [contactInfoPostalCode]
     * @param {string} [contactInfoCountry]
     * @param {string} [contactInfoPhoneNumber]
     * @param {number} [errorTypeErrorTypeId]
     * @param {string} [errorTypeName]
     * @param {boolean} [errorTypeActive]
     * @param {string} [userAgent]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCustomerCasesFormPost: async (
      userProductId: number,
      status: any,
      reason: any,
      servicePlace: any,
      subject: string,
      description: string,
      contactInfoMainContact: any,
      repairShopId?: number,
      photos?: Array<string>,
      contactInfoAddress?: string,
      contactInfoAddress2?: string,
      contactInfoCity?: string,
      contactInfoPostalCode?: string,
      contactInfoCountry?: string,
      contactInfoPhoneNumber?: string,
      errorTypeErrorTypeId?: number,
      errorTypeName?: string,
      errorTypeActive?: boolean,
      userAgent?: string,
      files?: Array<any>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userProductId' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'userProductId', userProductId)
      // verify required parameter 'status' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'status', status)
      // verify required parameter 'reason' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'reason', reason)
      // verify required parameter 'servicePlace' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'servicePlace', servicePlace)
      // verify required parameter 'subject' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'subject', subject)
      // verify required parameter 'description' is not null or undefined
      assertParamExists('apiCustomerCasesFormPost', 'description', description)
      // verify required parameter 'contactInfoMainContact' is not null or undefined
      assertParamExists(
        'apiCustomerCasesFormPost',
        'contactInfoMainContact',
        contactInfoMainContact,
      )
      const localVarPath = '/api/customerCases/Form'
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

      if (userProductId !== undefined) {
        localVarFormParams.append('UserProductId', userProductId as any)
      }

      if (status !== undefined) {
        localVarFormParams.append('Status', status as any)
      }

      if (reason !== undefined) {
        localVarFormParams.append('Reason', reason as any)
      }

      if (servicePlace !== undefined) {
        localVarFormParams.append('ServicePlace', servicePlace as any)
      }

      if (repairShopId !== undefined) {
        localVarFormParams.append('RepairShopId', repairShopId as any)
      }

      if (subject !== undefined) {
        localVarFormParams.append('Subject', subject as any)
      }

      if (description !== undefined) {
        localVarFormParams.append('Description', description as any)
      }
      if (photos) {
        localVarFormParams.append('Photos', photos.join(COLLECTION_FORMATS.csv))
      }

      if (contactInfoMainContact !== undefined) {
        localVarFormParams.append('ContactInfo.MainContact', contactInfoMainContact as any)
      }

      if (contactInfoAddress !== undefined) {
        localVarFormParams.append('ContactInfo.Address', contactInfoAddress as any)
      }

      if (contactInfoAddress2 !== undefined) {
        localVarFormParams.append('ContactInfo.Address2', contactInfoAddress2 as any)
      }

      if (contactInfoCity !== undefined) {
        localVarFormParams.append('ContactInfo.City', contactInfoCity as any)
      }

      if (contactInfoPostalCode !== undefined) {
        localVarFormParams.append('ContactInfo.PostalCode', contactInfoPostalCode as any)
      }

      if (contactInfoCountry !== undefined) {
        localVarFormParams.append('ContactInfo.Country', contactInfoCountry as any)
      }

      if (contactInfoPhoneNumber !== undefined) {
        localVarFormParams.append('ContactInfo.PhoneNumber', contactInfoPhoneNumber as any)
      }

      if (errorTypeErrorTypeId !== undefined) {
        localVarFormParams.append('ErrorType.ErrorTypeId', errorTypeErrorTypeId as any)
      }

      if (errorTypeName !== undefined) {
        localVarFormParams.append('ErrorType.Name', errorTypeName as any)
      }

      if (errorTypeActive !== undefined) {
        localVarFormParams.append('ErrorType.Active', errorTypeActive as any)
      }

      if (userAgent !== undefined) {
        localVarFormParams.append('UserAgent', userAgent as any)
      }
      if (files) {
        files.forEach((element) => {
          localVarFormParams.append('Files', element as any)
        })
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustomerCaseApi - functional programming interface
 * @export
 */
export const CustomerCaseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomerCaseApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} userProductId
     * @param {any} status
     * @param {any} reason
     * @param {any} servicePlace
     * @param {string} subject
     * @param {string} description
     * @param {any} contactInfoMainContact
     * @param {number} [repairShopId]
     * @param {Array<string>} [photos]
     * @param {string} [contactInfoAddress]
     * @param {string} [contactInfoAddress2]
     * @param {string} [contactInfoCity]
     * @param {string} [contactInfoPostalCode]
     * @param {string} [contactInfoCountry]
     * @param {string} [contactInfoPhoneNumber]
     * @param {number} [errorTypeErrorTypeId]
     * @param {string} [errorTypeName]
     * @param {boolean} [errorTypeActive]
     * @param {string} [userAgent]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCustomerCasesFormPost(
      userProductId: number,
      status: any,
      reason: any,
      servicePlace: any,
      subject: string,
      description: string,
      contactInfoMainContact: any,
      repairShopId?: number,
      photos?: Array<string>,
      contactInfoAddress?: string,
      contactInfoAddress2?: string,
      contactInfoCity?: string,
      contactInfoPostalCode?: string,
      contactInfoCountry?: string,
      contactInfoPhoneNumber?: string,
      errorTypeErrorTypeId?: number,
      errorTypeName?: string,
      errorTypeActive?: boolean,
      userAgent?: string,
      files?: Array<any>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesFormPost(
        userProductId,
        status,
        reason,
        servicePlace,
        subject,
        description,
        contactInfoMainContact,
        repairShopId,
        photos,
        contactInfoAddress,
        contactInfoAddress2,
        contactInfoCity,
        contactInfoPostalCode,
        contactInfoCountry,
        contactInfoPhoneNumber,
        errorTypeErrorTypeId,
        errorTypeName,
        errorTypeActive,
        userAgent,
        files,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CustomerCaseApi - factory interface
 * @export
 */
export const CustomerCaseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomerCaseApiFp(configuration)
  return {
    /**
     *
     * @param {number} userProductId
     * @param {any} status
     * @param {any} reason
     * @param {any} servicePlace
     * @param {string} subject
     * @param {string} description
     * @param {any} contactInfoMainContact
     * @param {number} [repairShopId]
     * @param {Array<string>} [photos]
     * @param {string} [contactInfoAddress]
     * @param {string} [contactInfoAddress2]
     * @param {string} [contactInfoCity]
     * @param {string} [contactInfoPostalCode]
     * @param {string} [contactInfoCountry]
     * @param {string} [contactInfoPhoneNumber]
     * @param {number} [errorTypeErrorTypeId]
     * @param {string} [errorTypeName]
     * @param {boolean} [errorTypeActive]
     * @param {string} [userAgent]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCustomerCasesFormPost(
      userProductId: number,
      status: any,
      reason: any,
      servicePlace: any,
      subject: string,
      description: string,
      contactInfoMainContact: any,
      repairShopId?: number,
      photos?: Array<string>,
      contactInfoAddress?: string,
      contactInfoAddress2?: string,
      contactInfoCity?: string,
      contactInfoPostalCode?: string,
      contactInfoCountry?: string,
      contactInfoPhoneNumber?: string,
      errorTypeErrorTypeId?: number,
      errorTypeName?: string,
      errorTypeActive?: boolean,
      userAgent?: string,
      files?: Array<any>,
      options?: any,
    ): AxiosPromise<CustomerCaseResponse> {
      return localVarFp
        .apiCustomerCasesFormPost(
          userProductId,
          status,
          reason,
          servicePlace,
          subject,
          description,
          contactInfoMainContact,
          repairShopId,
          photos,
          contactInfoAddress,
          contactInfoAddress2,
          contactInfoCity,
          contactInfoPostalCode,
          contactInfoCountry,
          contactInfoPhoneNumber,
          errorTypeErrorTypeId,
          errorTypeName,
          errorTypeActive,
          userAgent,
          files,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CustomerCaseApi - object-oriented interface
 * @export
 * @class CustomerCaseApi
 * @extends {BaseAPI}
 */
export class CustomApi extends BaseAPI {
  /**
   *
   * @param {number} userProductId
   * @param {any} status
   * @param {any} reason
   * @param {any} servicePlace
   * @param {string} subject
   * @param {string} description
   * @param {any} contactInfoMainContact
   * @param {number} [repairShopId]
   * @param {Array<string>} [photos]
   * @param {string} [contactInfoAddress]
   * @param {string} [contactInfoAddress2]
   * @param {string} [contactInfoCity]
   * @param {string} [contactInfoPostalCode]
   * @param {string} [contactInfoCountry]
   * @param {string} [contactInfoPhoneNumber]
   * @param {number} [errorTypeErrorTypeId]
   * @param {string} [errorTypeName]
   * @param {boolean} [errorTypeActive]
   * @param {string} [userAgent]
   * @param {Array<any>} [files]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerCaseApi
   */
  public apiCustomerCasesFormPost(
    userProductId: number,
    status: any,
    reason: any,
    servicePlace: any,
    subject: string,
    description: string,
    contactInfoMainContact: any,
    repairShopId?: number,
    photos?: Array<string>,
    contactInfoAddress?: string,
    contactInfoAddress2?: string,
    contactInfoCity?: string,
    contactInfoPostalCode?: string,
    contactInfoCountry?: string,
    contactInfoPhoneNumber?: string,
    errorTypeErrorTypeId?: number,
    errorTypeName?: string,
    errorTypeActive?: boolean,
    userAgent?: string,
    files?: Array<any>,
    options?: AxiosRequestConfig,
  ) {
    return CustomerCaseApiFp(this.configuration)
      .apiCustomerCasesFormPost(
        userProductId,
        status,
        reason,
        servicePlace,
        subject,
        description,
        contactInfoMainContact,
        repairShopId,
        photos,
        contactInfoAddress,
        contactInfoAddress2,
        contactInfoCity,
        contactInfoPostalCode,
        contactInfoCountry,
        contactInfoPhoneNumber,
        errorTypeErrorTypeId,
        errorTypeName,
        errorTypeActive,
        userAgent,
        files,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
