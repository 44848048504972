import { useQuery } from '@tanstack/react-query'
import { ERepairshopType, UserProductApi } from '../../services/Swagger/Services'
export interface Item {
  label: string
  value: string | number
}
export const useAddBike = () => {
  const brandCategoryProductQuery = useQuery(['api/brands'], async () => {
    const res = await new UserProductApi().apiUserProductsBrandCategoryProductSelectGet()
    return res.data
  })
  const getBrands = () => {
    if (brandCategoryProductQuery.data) {
      return brandCategoryProductQuery.data.brandRepairshops?.map((brand) => {
        return { label: brand.brandName, value: brand.brandId ?? 0 }
      }) as Item[]
    }
    return [] as Item[]
  }
  const getCategories = () => {
    if (brandCategoryProductQuery.data) {
      return brandCategoryProductQuery.data.categories?.map((category) => {
        return {
          label: category.categoryName ?? '',
          value: category.categoryId ?? 0,
          showSerialNumber: category.showSerialNumber,
        }
      })
    }
    return [] as Item[]
  }
  const getRepairShops = (brandId: number) => {
    if (brandCategoryProductQuery.data) {
      const brand = brandCategoryProductQuery.data.brandRepairshops?.find(
        (b) => b.brandId === brandId,
      )

      return brand?.purchasedPlaces
        ?.map((shop) => {
          return {
            label: shop.repairshopName ?? '',
            value: shop.repairshopId ?? 0,
            type:
              shop.type === ERepairshopType.OnlineShop
                ? ERepairshopType.OnlineShop
                : ERepairshopType.Bikeshop,
          }
        })
        .sort((a, b) => -b.type.localeCompare(a.type))
    }
    return [] as Item[]
  }

  const getListBikes = (brandId?: number, categoryId?: number) => {
    if (brandCategoryProductQuery.data) {
      const products = brandCategoryProductQuery.data.products?.filter((product) => {
        let check = true
        if (brandId && brandId !== product.brandId) check = false
        if (categoryId && categoryId !== product.categoryId) check = false
        return check
      })
      return products?.map((bike) => {
        return { label: bike.productName ?? '', value: bike.productId ?? 0, data: bike }
      })
    }
    return []
  }

  return {
    brands: getBrands(),
    categories: getCategories(),
    getRepairShops,
    getListBikes,
    isLoading: brandCategoryProductQuery.isLoading,
  }
}
