import axios from 'axios'

export function isFakeEmail(email: string) {
  return email && email.split('#ttp_').length > 1 ? true : false
}

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function getFileBase64(file: any) {
  return new Promise((resolve) => {
    let baseURL = ''
    // Make new FileReader
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as string
      resolve(baseURL)
    }
  })
}
export async function getMyIpAddress() {
  try {
    const { data } = await axios.get('//www.cloudflare.com/cdn-cgi/trace', { headers: {} })
    const ip = data
      .trim()
      .split('\n')
      .reduce((obj: any, pair: any) => {
        pair = pair.split('=')
        return (obj[pair[0]] = pair[1]), obj
      }, {})
    return ip
  } catch (ex: any) {
    return '0.0.0.0'
  }
}

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const getTextAvatar = (value: string) => {
  if (!value) return ''
  const values = value.split(' ')
  if (values.length == 1) return values[0].charAt(0).toUpperCase()
  return values[0].charAt(0).toUpperCase() + values[values.length - 1].charAt(0).toUpperCase()
}
