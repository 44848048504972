import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppBar } from '../../components'
import { updateSnackbarState } from '../../redux'
import Storage, { LOGIN_USER, USER_PROFILE } from '../../services/Storage'
import { CustLoginDto, AuthApi, AccountApi } from '../../services/Swagger/Services'
import { useGoogleLogin } from '@react-oauth/google'
import { Colors } from '../../theme'
import { useTranslation } from 'react-i18next'
import yup from '../../services/Translations/yup'

const loginSchema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
})

function Login() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loginModel] = useState<CustLoginDto>({
    email: '',
    password: '',
    uuid: '',
    deviceId: navigator.userAgent,
    deviceToken: '',
  })
  const onLoginSubmit = async (values: CustLoginDto) => {
    setIsSubmitting(true)
    try {
      const { data } = await new AuthApi().apiAuthsApploginPost(values)
      Storage.setItem(LOGIN_USER, data)
      const response = await new AccountApi().apiAccountsCustomerProfileGet()
      Storage.setItem(USER_PROFILE, response.data)
      navigate('/')
    } catch (error: any) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Please check your password',
          type: 'error',
        } as any),
      )
    }
    setIsSubmitting(false)
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      new AuthApi()
        .apiAuthsLoginGooglePost({
          ...credentialResponse,
          uuid: '',
          device_id: navigator.userAgent,
          device_token: '',
        })
        .then(async ({ data }) => {
          Storage.setItem(LOGIN_USER, data)
          const response = await new AccountApi().apiAccountsCustomerProfileGet()
          Storage.setItem(USER_PROFILE, response.data)
          navigate('/')
        })
    },
  })
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('login')}</title>
      </Helmet>
      <AppBar title={t('login')} />
      <Container maxWidth={'sm'}>
        <Box sx={{ mt: 8, mb: 18 }}>
          <img src={require('../../assets/images/logo.png')} width='100%' alt='logo' />
        </Box>
        <Formik initialValues={loginModel} onSubmit={onLoginSubmit} validationSchema={loginSchema}>
          {({ handleChange, handleSubmit, values, errors }) => (
            <>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('email')}</Typography>
                <TextField
                  value={values.email}
                  onChange={handleChange('email')}
                  fullWidth
                  variant='outlined'
                />
                {errors.email && <Typography className='textError'>{errors.email}</Typography>}
              </Box>
              <Box sx={{ mb: 8 }}>
                <Typography variant='caption'>{t('password')}</Typography>
                <TextField
                  value={values.password}
                  onChange={handleChange('password')}
                  fullWidth
                  type='password'
                  variant='outlined'
                />
                {errors.password && (
                  <Typography className='textError'>{errors.password}</Typography>
                )}
              </Box>
              <Box sx={{ mb: 6 }}>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant='contained'
                  onClick={() => handleSubmit()}
                >
                  {t('login')}
                </Button>
              </Box>
              <Box sx={{ mb: 8 }}>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={() => googleLogin()}
                  disabled={isSubmitting}
                >
                  <Box sx={{ mr: 3, display: 'flex', alignItems: 'center' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='24'
                      viewBox='0 0 24 24'
                      width='24'
                    >
                      <path
                        d='M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z'
                        fill='#4285F4'
                      />
                      <path
                        d='M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z'
                        fill='#34A853'
                      />
                      <path
                        d='M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z'
                        fill='#FBBC05'
                      />
                      <path
                        d='M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z'
                        fill='#EA4335'
                      />
                      <path d='M1 1h22v22H1z' fill='none' />
                    </svg>
                  </Box>
                  Login med Google
                </Button>
              </Box>
              <Typography sx={{ color: Colors.primary, mb: 8 }} align='center'>
                <Link to='/auth/forgot'>{t('forgotPassword')}</Link>
              </Typography>
              <Typography sx={{ mb: 4 }} align='center'>
                {t('Do not have account?')}{' '}
                <Link to='/auth/signup' style={{ color: Colors.primary }}>
                  {t('signUp')}
                </Link>
              </Typography>
            </>
          )}
        </Formik>
      </Container>
    </Box>
  )
}

export default Login
