import { Box, Breadcrumbs, Button, Container, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { AppBar, Loading } from '../../components'
import { CustomerCaseApi } from '../../services/Swagger/Services'
import { useTranslation } from 'react-i18next'

function Add() {
  const { data, isLoading } = useQuery(['/api/customerCases/userProductSelect'], async () => {
    const res = await new CustomerCaseApi().apiCustomerCasesUserProductSelectGet()
    return res.data
  })
  const { t } = useTranslation()
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('myBikes')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'lg'}>
        <Box justifyContent={'space-between'} display='flex' alignItems={'center'} sx={{ mt: 6 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/'>Cykelmakker</Link>
            <Typography color='text.primary'>{t('myBikes')}</Typography>
          </Breadcrumbs>
          <Link to='/create-bike'>
            <Button fullWidth variant='contained' size='small'>
              {t('registerABike')}
            </Button>
          </Link>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <Box>
            {data &&
              data?.length > 0 &&
              data?.map((product) => {
                return (
                  <Link key={product.userProductId} to={`/bike?id=${product.userProductId}`}>
                    <Box sx={{ my: 6 }} className='boxProduct'>
                      <img src={require('../../assets/images/cykel.png')} width='100%' alt='logo' />
                      <Typography variant='h4'>{product.userProductName}</Typography>
                    </Box>
                  </Link>
                )
              })}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default Add
