/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerCaseDetails } from '../models';
// @ts-ignore
import { CustomerCasePostPut } from '../models';
// @ts-ignore
import { CustomerCaseResponse } from '../models';
// @ts-ignore
import { CustomerCaseResponsePagingResult } from '../models';
// @ts-ignore
import { CustomerCaseUserProductSelect } from '../models';
// @ts-ignore
import { FilterCaseModel } from '../models';
// @ts-ignore
import { PurchaseOrderPayment } from '../models';
// @ts-ignore
import { PurchaseOrderPaymentIntentResponse } from '../models';
/**
 * CustomerCaseApi - axios parameter creator
 * @export
 */
export const CustomerCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific case by caseId, allow for unsent status
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesByCaseIdDelete: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCustomerCasesByCaseIdDelete', 'caseId', caseId)
            const localVarPath = `/api/customerCases/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an exist case from customer
         * @param {number} caseId caseId
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesByCaseIdPut: async (caseId: number, body?: CustomerCasePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCustomerCasesByCaseIdPut', 'caseId', caseId)
            const localVarPath = `/api/customerCases/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesCheckTimeStampGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customerCases/checkTimeStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create clientSecret for client
         * @param {number} amount 
         * @param {any} currency 
         * @param {number} [purchaseOrderId] 
         * @param {string} [purchaseOrderCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesCreatePaymentIntentPost: async (amount: number, currency: any, purchaseOrderId?: number, purchaseOrderCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('apiCustomerCasesCreatePaymentIntentPost', 'amount', amount)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiCustomerCasesCreatePaymentIntentPost', 'currency', currency)
            const localVarPath = `/api/customerCases/createPaymentIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (purchaseOrderId !== undefined) {
                localVarQueryParameter['PurchaseOrderId'] = purchaseOrderId;
            }

            if (purchaseOrderCode !== undefined) {
                localVarQueryParameter['PurchaseOrderCode'] = purchaseOrderCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves case details by caseId
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesDetailsByCaseIdGet: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCustomerCasesDetailsByCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/customerCases/details/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userProductId 
         * @param {any} status 
         * @param {any} reason 
         * @param {any} servicePlace 
         * @param {string} subject 
         * @param {string} description 
         * @param {any} contactInfoMainContact 
         * @param {number} [repairShopId] 
         * @param {Array<string>} [photos] 
         * @param {string} [contactInfoAddress] 
         * @param {string} [contactInfoAddress2] 
         * @param {string} [contactInfoCity] 
         * @param {string} [contactInfoPostalCode] 
         * @param {string} [contactInfoCountry] 
         * @param {string} [contactInfoPhoneNumber] 
         * @param {number} [errorTypeErrorTypeId] 
         * @param {string} [errorTypeName] 
         * @param {boolean} [errorTypeActive] 
         * @param {string} [userAgent] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesFormPost: async (userProductId: number, status: any, reason: any, servicePlace: any, subject: string, description: string, contactInfoMainContact: any, repairShopId?: number, photos?: Array<string>, contactInfoAddress?: string, contactInfoAddress2?: string, contactInfoCity?: string, contactInfoPostalCode?: string, contactInfoCountry?: string, contactInfoPhoneNumber?: string, errorTypeErrorTypeId?: number, errorTypeName?: string, errorTypeActive?: boolean, userAgent?: string, files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProductId' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'userProductId', userProductId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'status', status)
            // verify required parameter 'reason' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'reason', reason)
            // verify required parameter 'servicePlace' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'servicePlace', servicePlace)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'subject', subject)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'description', description)
            // verify required parameter 'contactInfoMainContact' is not null or undefined
            assertParamExists('apiCustomerCasesFormPost', 'contactInfoMainContact', contactInfoMainContact)
            const localVarPath = `/api/customerCases/Form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (userProductId !== undefined) { 
                localVarFormParams.append('UserProductId', userProductId as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('Status', new Blob([JSON.stringify(status)], { type: "application/json", }));
            }
    
            if (reason !== undefined) { 
                localVarFormParams.append('Reason', new Blob([JSON.stringify(reason)], { type: "application/json", }));
            }
    
            if (servicePlace !== undefined) { 
                localVarFormParams.append('ServicePlace', new Blob([JSON.stringify(servicePlace)], { type: "application/json", }));
            }
    
            if (repairShopId !== undefined) { 
                localVarFormParams.append('RepairShopId', repairShopId as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('Subject', subject as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
                if (photos) {
                localVarFormParams.append('Photos', photos.join(COLLECTION_FORMATS.csv));
            }

    
            if (contactInfoMainContact !== undefined) { 
                localVarFormParams.append('ContactInfo.MainContact', new Blob([JSON.stringify(contactInfoMainContact)], { type: "application/json", }));
            }
    
            if (contactInfoAddress !== undefined) { 
                localVarFormParams.append('ContactInfo.Address', contactInfoAddress as any);
            }
    
            if (contactInfoAddress2 !== undefined) { 
                localVarFormParams.append('ContactInfo.Address2', contactInfoAddress2 as any);
            }
    
            if (contactInfoCity !== undefined) { 
                localVarFormParams.append('ContactInfo.City', contactInfoCity as any);
            }
    
            if (contactInfoPostalCode !== undefined) { 
                localVarFormParams.append('ContactInfo.PostalCode', contactInfoPostalCode as any);
            }
    
            if (contactInfoCountry !== undefined) { 
                localVarFormParams.append('ContactInfo.Country', contactInfoCountry as any);
            }
    
            if (contactInfoPhoneNumber !== undefined) { 
                localVarFormParams.append('ContactInfo.PhoneNumber', contactInfoPhoneNumber as any);
            }
    
            if (errorTypeErrorTypeId !== undefined) { 
                localVarFormParams.append('ErrorType.ErrorTypeId', errorTypeErrorTypeId as any);
            }
    
            if (errorTypeName !== undefined) { 
                localVarFormParams.append('ErrorType.Name', errorTypeName as any);
            }
    
            if (errorTypeActive !== undefined) { 
                localVarFormParams.append('ErrorType.Active', errorTypeActive as any);
            }
    
            if (userAgent !== undefined) { 
                localVarFormParams.append('UserAgent', userAgent as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves cases of current user
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesGet: async (body?: FilterCaseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customerCases/getList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay for purchase order
         * @param {PurchaseOrderPayment} [body] amount, PO.ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesPayPurchaseOrderPut: async (body?: PurchaseOrderPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customerCases/payPurchaseOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new case from customer
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesPost: async (body?: CustomerCasePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customerCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send case to SP
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesSendCaseByCaseIdPut: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCustomerCasesSendCaseByCaseIdPut', 'caseId', caseId)
            const localVarPath = `/api/customerCases/sendCase/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves products belong to current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesUserProductSelectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customerCases/userProductSelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves cases of Customer
         * @param {number} customerId 
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListCustomerCasesGet: async (customerId: number, body?: FilterCaseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiListCustomerCasesGet', 'customerId', customerId)
            const localVarPath = `/api/customerCases/getListCustomerCase/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCaseApi - functional programming interface
 * @export
 */
export const CustomerCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a specific case by caseId, allow for unsent status
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesByCaseIdDelete(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesByCaseIdDelete(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an exist case from customer
         * @param {number} caseId caseId
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesByCaseIdPut(caseId: number, body?: CustomerCasePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesByCaseIdPut(caseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesCheckTimeStampGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesCheckTimeStampGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create clientSecret for client
         * @param {number} amount 
         * @param {any} currency 
         * @param {number} [purchaseOrderId] 
         * @param {string} [purchaseOrderCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesCreatePaymentIntentPost(amount: number, currency: any, purchaseOrderId?: number, purchaseOrderCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderPaymentIntentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesCreatePaymentIntentPost(amount, currency, purchaseOrderId, purchaseOrderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves case details by caseId
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesDetailsByCaseIdGet(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesDetailsByCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userProductId 
         * @param {any} status 
         * @param {any} reason 
         * @param {any} servicePlace 
         * @param {string} subject 
         * @param {string} description 
         * @param {any} contactInfoMainContact 
         * @param {number} [repairShopId] 
         * @param {Array<string>} [photos] 
         * @param {string} [contactInfoAddress] 
         * @param {string} [contactInfoAddress2] 
         * @param {string} [contactInfoCity] 
         * @param {string} [contactInfoPostalCode] 
         * @param {string} [contactInfoCountry] 
         * @param {string} [contactInfoPhoneNumber] 
         * @param {number} [errorTypeErrorTypeId] 
         * @param {string} [errorTypeName] 
         * @param {boolean} [errorTypeActive] 
         * @param {string} [userAgent] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesFormPost(userProductId: number, status: any, reason: any, servicePlace: any, subject: string, description: string, contactInfoMainContact: any, repairShopId?: number, photos?: Array<string>, contactInfoAddress?: string, contactInfoAddress2?: string, contactInfoCity?: string, contactInfoPostalCode?: string, contactInfoCountry?: string, contactInfoPhoneNumber?: string, errorTypeErrorTypeId?: number, errorTypeName?: string, errorTypeActive?: boolean, userAgent?: string, files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesFormPost(userProductId, status, reason, servicePlace, subject, description, contactInfoMainContact, repairShopId, photos, contactInfoAddress, contactInfoAddress2, contactInfoCity, contactInfoPostalCode, contactInfoCountry, contactInfoPhoneNumber, errorTypeErrorTypeId, errorTypeName, errorTypeActive, userAgent, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves cases of current user
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesGet(body?: FilterCaseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponsePagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pay for purchase order
         * @param {PurchaseOrderPayment} [body] amount, PO.ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesPayPurchaseOrderPut(body?: PurchaseOrderPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesPayPurchaseOrderPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new case from customer
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesPost(body?: CustomerCasePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send case to SP
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesSendCaseByCaseIdPut(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesSendCaseByCaseIdPut(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves products belong to current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCasesUserProductSelectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCaseUserProductSelect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCasesUserProductSelectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves cases of Customer
         * @param {number} customerId 
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListCustomerCasesGet(customerId: number, body?: FilterCaseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCaseResponsePagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListCustomerCasesGet(customerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerCaseApi - factory interface
 * @export
 */
export const CustomerCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerCaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a specific case by caseId, allow for unsent status
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesByCaseIdDelete(caseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCasesByCaseIdDelete(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an exist case from customer
         * @param {number} caseId caseId
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesByCaseIdPut(caseId: number, body?: CustomerCasePostPut, options?: any): AxiosPromise<CustomerCaseResponse> {
            return localVarFp.apiCustomerCasesByCaseIdPut(caseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesCheckTimeStampGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCasesCheckTimeStampGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create clientSecret for client
         * @param {number} amount 
         * @param {any} currency 
         * @param {number} [purchaseOrderId] 
         * @param {string} [purchaseOrderCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesCreatePaymentIntentPost(amount: number, currency: any, purchaseOrderId?: number, purchaseOrderCode?: string, options?: any): AxiosPromise<PurchaseOrderPaymentIntentResponse> {
            return localVarFp.apiCustomerCasesCreatePaymentIntentPost(amount, currency, purchaseOrderId, purchaseOrderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves case details by caseId
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesDetailsByCaseIdGet(caseId: number, options?: any): AxiosPromise<CustomerCaseDetails> {
            return localVarFp.apiCustomerCasesDetailsByCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userProductId 
         * @param {any} status 
         * @param {any} reason 
         * @param {any} servicePlace 
         * @param {string} subject 
         * @param {string} description 
         * @param {any} contactInfoMainContact 
         * @param {number} [repairShopId] 
         * @param {Array<string>} [photos] 
         * @param {string} [contactInfoAddress] 
         * @param {string} [contactInfoAddress2] 
         * @param {string} [contactInfoCity] 
         * @param {string} [contactInfoPostalCode] 
         * @param {string} [contactInfoCountry] 
         * @param {string} [contactInfoPhoneNumber] 
         * @param {number} [errorTypeErrorTypeId] 
         * @param {string} [errorTypeName] 
         * @param {boolean} [errorTypeActive] 
         * @param {string} [userAgent] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesFormPost(userProductId: number, status: any, reason: any, servicePlace: any, subject: string, description: string, contactInfoMainContact: any, repairShopId?: number, photos?: Array<string>, contactInfoAddress?: string, contactInfoAddress2?: string, contactInfoCity?: string, contactInfoPostalCode?: string, contactInfoCountry?: string, contactInfoPhoneNumber?: string, errorTypeErrorTypeId?: number, errorTypeName?: string, errorTypeActive?: boolean, userAgent?: string, files?: Array<any>, options?: any): AxiosPromise<CustomerCaseResponse> {
            return localVarFp.apiCustomerCasesFormPost(userProductId, status, reason, servicePlace, subject, description, contactInfoMainContact, repairShopId, photos, contactInfoAddress, contactInfoAddress2, contactInfoCity, contactInfoPostalCode, contactInfoCountry, contactInfoPhoneNumber, errorTypeErrorTypeId, errorTypeName, errorTypeActive, userAgent, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves cases of current user
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesGet(body?: FilterCaseModel, options?: any): AxiosPromise<CustomerCaseResponsePagingResult> {
            return localVarFp.apiCustomerCasesGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pay for purchase order
         * @param {PurchaseOrderPayment} [body] amount, PO.ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesPayPurchaseOrderPut(body?: PurchaseOrderPayment, options?: any): AxiosPromise<CustomerCaseDetails> {
            return localVarFp.apiCustomerCasesPayPurchaseOrderPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new case from customer
         * @param {CustomerCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesPost(body?: CustomerCasePostPut, options?: any): AxiosPromise<CustomerCaseResponse> {
            return localVarFp.apiCustomerCasesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send case to SP
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesSendCaseByCaseIdPut(caseId: number, options?: any): AxiosPromise<CustomerCaseResponse> {
            return localVarFp.apiCustomerCasesSendCaseByCaseIdPut(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves products belong to current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCasesUserProductSelectGet(options?: any): AxiosPromise<Array<CustomerCaseUserProductSelect>> {
            return localVarFp.apiCustomerCasesUserProductSelectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves cases of Customer
         * @param {number} customerId 
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListCustomerCasesGet(customerId: number, body?: FilterCaseModel, options?: any): AxiosPromise<CustomerCaseResponsePagingResult> {
            return localVarFp.apiListCustomerCasesGet(customerId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerCaseApi - object-oriented interface
 * @export
 * @class CustomerCaseApi
 * @extends {BaseAPI}
 */
export class CustomerCaseApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific case by caseId, allow for unsent status
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesByCaseIdDelete(caseId: number, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesByCaseIdDelete(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an exist case from customer
     * @param {number} caseId caseId
     * @param {CustomerCasePostPut} [body] case info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesByCaseIdPut(caseId: number, body?: CustomerCasePostPut, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesByCaseIdPut(caseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesCheckTimeStampGet(options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesCheckTimeStampGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create clientSecret for client
     * @param {number} amount 
     * @param {any} currency 
     * @param {number} [purchaseOrderId] 
     * @param {string} [purchaseOrderCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesCreatePaymentIntentPost(amount: number, currency: any, purchaseOrderId?: number, purchaseOrderCode?: string, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesCreatePaymentIntentPost(amount, currency, purchaseOrderId, purchaseOrderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves case details by caseId
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesDetailsByCaseIdGet(caseId: number, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesDetailsByCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userProductId 
     * @param {any} status 
     * @param {any} reason 
     * @param {any} servicePlace 
     * @param {string} subject 
     * @param {string} description 
     * @param {any} contactInfoMainContact 
     * @param {number} [repairShopId] 
     * @param {Array<string>} [photos] 
     * @param {string} [contactInfoAddress] 
     * @param {string} [contactInfoAddress2] 
     * @param {string} [contactInfoCity] 
     * @param {string} [contactInfoPostalCode] 
     * @param {string} [contactInfoCountry] 
     * @param {string} [contactInfoPhoneNumber] 
     * @param {number} [errorTypeErrorTypeId] 
     * @param {string} [errorTypeName] 
     * @param {boolean} [errorTypeActive] 
     * @param {string} [userAgent] 
     * @param {Array<any>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesFormPost(userProductId: number, status: any, reason: any, servicePlace: any, subject: string, description: string, contactInfoMainContact: any, repairShopId?: number, photos?: Array<string>, contactInfoAddress?: string, contactInfoAddress2?: string, contactInfoCity?: string, contactInfoPostalCode?: string, contactInfoCountry?: string, contactInfoPhoneNumber?: string, errorTypeErrorTypeId?: number, errorTypeName?: string, errorTypeActive?: boolean, userAgent?: string, files?: Array<any>, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesFormPost(userProductId, status, reason, servicePlace, subject, description, contactInfoMainContact, repairShopId, photos, contactInfoAddress, contactInfoAddress2, contactInfoCity, contactInfoPostalCode, contactInfoCountry, contactInfoPhoneNumber, errorTypeErrorTypeId, errorTypeName, errorTypeActive, userAgent, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves cases of current user
     * @param {FilterCaseModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesGet(body?: FilterCaseModel, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pay for purchase order
     * @param {PurchaseOrderPayment} [body] amount, PO.ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesPayPurchaseOrderPut(body?: PurchaseOrderPayment, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesPayPurchaseOrderPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new case from customer
     * @param {CustomerCasePostPut} [body] case info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesPost(body?: CustomerCasePostPut, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send case to SP
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesSendCaseByCaseIdPut(caseId: number, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesSendCaseByCaseIdPut(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves products belong to current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiCustomerCasesUserProductSelectGet(options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiCustomerCasesUserProductSelectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves cases of Customer
     * @param {number} customerId 
     * @param {FilterCaseModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCaseApi
     */
    public apiListCustomerCasesGet(customerId: number, body?: FilterCaseModel, options?: AxiosRequestConfig) {
        return CustomerCaseApiFp(this.configuration).apiListCustomerCasesGet(customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
