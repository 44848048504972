/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExportExcelRequest } from '../models';
// @ts-ignore
import { ExportExcelResponse } from '../models';
// @ts-ignore
import { FilterCaseModel } from '../models';
// @ts-ignore
import { MessageDetails } from '../models';
// @ts-ignore
import { ServiceProviderCaseCustomerUserProductSelect } from '../models';
// @ts-ignore
import { ServiceProviderCaseDetails } from '../models';
// @ts-ignore
import { ServiceProviderCaseDistanceToRepairshop } from '../models';
// @ts-ignore
import { ServiceProviderCaseListResponse } from '../models';
// @ts-ignore
import { ServiceProviderCaseNewUnAssignedSelect } from '../models';
// @ts-ignore
import { ServiceProviderCaseOverView } from '../models';
// @ts-ignore
import { ServiceProviderCaseOverViewAssignRepairer } from '../models';
// @ts-ignore
import { ServiceProviderCaseOverViewAssignRepairshop } from '../models';
// @ts-ignore
import { ServiceProviderCasePostPut } from '../models';
// @ts-ignore
import { ServiceProviderCaseResponsePagingResult } from '../models';
/**
 * ServiceProviderCaseApi - axios parameter creator
 * @export
 */
export const ServiceProviderCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign to repairer
         * @param {ServiceProviderCaseOverViewAssignRepairer} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesAssignToRepairerPut: async (body?: ServiceProviderCaseOverViewAssignRepairer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/assignToRepairer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign to repairshop
         * @param {ServiceProviderCaseOverViewAssignRepairshop} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesAssignToRepairshopPut: async (body?: ServiceProviderCaseOverViewAssignRepairshop, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/assignToRepairshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves count total cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesCountTotalCasesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/countTotalCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves list customer and products belong to the customer
         * @param {number} page 
         * @param {number} limit 
         * @param {string} search Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesCustomerUserProductSelectGet: async (page: number, limit: number, search: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiServiceProviderCasesCustomerUserProductSelectGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiServiceProviderCasesCustomerUserProductSelectGet', 'limit', limit)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('apiServiceProviderCasesCustomerUserProductSelectGet', 'search', search)
            const localVarPath = `/api/serviceProviderCases/customerUserProductSelect/{page}/{limit}/{search}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)))
                .replace(`{${"search"}}`, encodeURIComponent(String(search)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves case details by id
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesDetailsByCaseIdGet: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiServiceProviderCasesDetailsByCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/serviceProviderCases/details/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export excel
         * @param {ExportExcelRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesExportExcelPut: async (body?: ExportExcelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/exportExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves case by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} status 
         * @param {any} servicePlace 
         * @param {number} repairShopId 
         * @param {any} typeOfDuration 
         * @param {string} orderBy 
         * @param {string} [search] 
         * @param {boolean} [isDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesGetByFilterGet: async (page: number, limit: number, status: any, servicePlace: any, repairShopId: number, typeOfDuration: any, orderBy: string, search?: string, isDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'limit', limit)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'status', status)
            // verify required parameter 'servicePlace' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'servicePlace', servicePlace)
            // verify required parameter 'repairShopId' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'repairShopId', repairShopId)
            // verify required parameter 'typeOfDuration' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'typeOfDuration', typeOfDuration)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('apiServiceProviderCasesGetByFilterGet', 'orderBy', orderBy)
            const localVarPath = `/api/serviceProviderCases/getByFilter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (servicePlace !== undefined) {
                localVarQueryParameter['ServicePlace'] = servicePlace;
            }

            if (repairShopId !== undefined) {
                localVarQueryParameter['RepairShopId'] = repairShopId;
            }

            if (typeOfDuration !== undefined) {
                localVarQueryParameter['TypeOfDuration'] = typeOfDuration;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['IsDesc'] = isDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves List Open Case In Dashboard
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesListCaseDeadlineGet: async (body?: FilterCaseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/getListCaseDeadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves unassign list cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesNewUnAssignedCaseListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases/newUnAssignedCaseList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves overview details of a specific case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesOverViewByCaseIdGet: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiServiceProviderCasesOverViewByCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/serviceProviderCases/overView/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update overview model of an case
         * @param {any} status 
         * @param {any} priority 
         * @param {any} servicePlace 
         * @param {number} [caseId] 
         * @param {string} [caseCode] 
         * @param {boolean} [paymentWaiting] 
         * @param {number} [repairshopId] 
         * @param {string} [address] 
         * @param {number} [serviceTimeTick] 
         * @param {Array<MessageDetails>} [messageToRepairer] 
         * @param {Array<MessageDetails>} [messageToCustomer] 
         * @param {string} [repairerId] 
         * @param {boolean} [repairerConfirmed] 
         * @param {boolean} [sendEmailToCustomer] 
         * @param {boolean} [sendEmailToRepairer] 
         * @param {number} [errorTypeId] 
         * @param {Array<string>} [files] 
         * @param {string} [description] 
         * @param {Array<string>} [listPhoto] 
         * @param {boolean} [sendNotificationToCustomer] 
         * @param {Array<any>} [newFiles] 
         * @param {string} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesOverviewModelPut: async (status: any, priority: any, servicePlace: any, caseId?: number, caseCode?: string, paymentWaiting?: boolean, repairshopId?: number, address?: string, serviceTimeTick?: number, messageToRepairer?: Array<MessageDetails>, messageToCustomer?: Array<MessageDetails>, repairerId?: string, repairerConfirmed?: boolean, sendEmailToCustomer?: boolean, sendEmailToRepairer?: boolean, errorTypeId?: number, files?: Array<string>, description?: string, listPhoto?: Array<string>, sendNotificationToCustomer?: boolean, newFiles?: Array<any>, solution?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiServiceProviderCasesOverviewModelPut', 'status', status)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('apiServiceProviderCasesOverviewModelPut', 'priority', priority)
            // verify required parameter 'servicePlace' is not null or undefined
            assertParamExists('apiServiceProviderCasesOverviewModelPut', 'servicePlace', servicePlace)
            const localVarPath = `/api/serviceProviderCases/overviewModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (caseId !== undefined) { 
                localVarFormParams.append('CaseId', caseId as any);
            }
    
            if (caseCode !== undefined) { 
                localVarFormParams.append('CaseCode', caseCode as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('Status', new Blob([JSON.stringify(status)], { type: "application/json", }));
            }
    
            if (paymentWaiting !== undefined) { 
                localVarFormParams.append('PaymentWaiting', paymentWaiting as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('Priority', new Blob([JSON.stringify(priority)], { type: "application/json", }));
            }
    
            if (repairshopId !== undefined) { 
                localVarFormParams.append('RepairshopId', repairshopId as any);
            }
    
            if (servicePlace !== undefined) { 
                localVarFormParams.append('ServicePlace', new Blob([JSON.stringify(servicePlace)], { type: "application/json", }));
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (serviceTimeTick !== undefined) { 
                localVarFormParams.append('ServiceTimeTick', serviceTimeTick as any);
            }
                if (messageToRepairer) {
                localVarFormParams.append('MessageToRepairer', messageToRepairer.join(COLLECTION_FORMATS.csv));
            }

                if (messageToCustomer) {
                localVarFormParams.append('MessageToCustomer', messageToCustomer.join(COLLECTION_FORMATS.csv));
            }

    
            if (repairerId !== undefined) { 
                localVarFormParams.append('RepairerId', repairerId as any);
            }
    
            if (repairerConfirmed !== undefined) { 
                localVarFormParams.append('RepairerConfirmed', repairerConfirmed as any);
            }
    
            if (sendEmailToCustomer !== undefined) { 
                localVarFormParams.append('SendEmailToCustomer', sendEmailToCustomer as any);
            }
    
            if (sendEmailToRepairer !== undefined) { 
                localVarFormParams.append('SendEmailToRepairer', sendEmailToRepairer as any);
            }
    
            if (errorTypeId !== undefined) { 
                localVarFormParams.append('ErrorTypeId', errorTypeId as any);
            }
                if (files) {
                localVarFormParams.append('Files', files.join(COLLECTION_FORMATS.csv));
            }

    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
                if (listPhoto) {
                localVarFormParams.append('ListPhoto', listPhoto.join(COLLECTION_FORMATS.csv));
            }

    
            if (sendNotificationToCustomer !== undefined) { 
                localVarFormParams.append('SendNotificationToCustomer', sendNotificationToCustomer as any);
            }
                if (newFiles) {
                newFiles.forEach((element) => {
                    localVarFormParams.append('NewFiles', element as any);
                })
            }

    
            if (solution !== undefined) { 
                localVarFormParams.append('Solution', solution as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new case from SP
         * @param {ServiceProviderCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesPost: async (body?: ServiceProviderCasePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/serviceProviderCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to the case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesRepairshopDistanceByCaseIdGet: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiServiceProviderCasesRepairshopDistanceByCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/serviceProviderCases/repairshopDistance/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to customer
         * @param {string} userId Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet', 'userId', userId)
            const localVarPath = `/api/serviceProviderCases/repairshopDistanceToCustomer/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceProviderCaseApi - functional programming interface
 * @export
 */
export const ServiceProviderCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceProviderCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign to repairer
         * @param {ServiceProviderCaseOverViewAssignRepairer} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesAssignToRepairerPut(body?: ServiceProviderCaseOverViewAssignRepairer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesAssignToRepairerPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign to repairshop
         * @param {ServiceProviderCaseOverViewAssignRepairshop} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesAssignToRepairshopPut(body?: ServiceProviderCaseOverViewAssignRepairshop, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesAssignToRepairshopPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves count total cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesCountTotalCasesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesCountTotalCasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves list customer and products belong to the customer
         * @param {number} page 
         * @param {number} limit 
         * @param {string} search Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesCustomerUserProductSelectGet(page: number, limit: number, search: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceProviderCaseCustomerUserProductSelect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesCustomerUserProductSelectGet(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves case details by id
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesDetailsByCaseIdGet(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesDetailsByCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export excel
         * @param {ExportExcelRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesExportExcelPut(body?: ExportExcelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesExportExcelPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves case by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} status 
         * @param {any} servicePlace 
         * @param {number} repairShopId 
         * @param {any} typeOfDuration 
         * @param {string} orderBy 
         * @param {string} [search] 
         * @param {boolean} [isDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesGetByFilterGet(page: number, limit: number, status: any, servicePlace: any, repairShopId: number, typeOfDuration: any, orderBy: string, search?: string, isDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesGetByFilterGet(page, limit, status, servicePlace, repairShopId, typeOfDuration, orderBy, search, isDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves List Open Case In Dashboard
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesListCaseDeadlineGet(body?: FilterCaseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseResponsePagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesListCaseDeadlineGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves unassign list cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesNewUnAssignedCaseListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceProviderCaseNewUnAssignedSelect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesNewUnAssignedCaseListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves overview details of a specific case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesOverViewByCaseIdGet(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseOverView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesOverViewByCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update overview model of an case
         * @param {any} status 
         * @param {any} priority 
         * @param {any} servicePlace 
         * @param {number} [caseId] 
         * @param {string} [caseCode] 
         * @param {boolean} [paymentWaiting] 
         * @param {number} [repairshopId] 
         * @param {string} [address] 
         * @param {number} [serviceTimeTick] 
         * @param {Array<MessageDetails>} [messageToRepairer] 
         * @param {Array<MessageDetails>} [messageToCustomer] 
         * @param {string} [repairerId] 
         * @param {boolean} [repairerConfirmed] 
         * @param {boolean} [sendEmailToCustomer] 
         * @param {boolean} [sendEmailToRepairer] 
         * @param {number} [errorTypeId] 
         * @param {Array<string>} [files] 
         * @param {string} [description] 
         * @param {Array<string>} [listPhoto] 
         * @param {boolean} [sendNotificationToCustomer] 
         * @param {Array<any>} [newFiles] 
         * @param {string} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesOverviewModelPut(status: any, priority: any, servicePlace: any, caseId?: number, caseCode?: string, paymentWaiting?: boolean, repairshopId?: number, address?: string, serviceTimeTick?: number, messageToRepairer?: Array<MessageDetails>, messageToCustomer?: Array<MessageDetails>, repairerId?: string, repairerConfirmed?: boolean, sendEmailToCustomer?: boolean, sendEmailToRepairer?: boolean, errorTypeId?: number, files?: Array<string>, description?: string, listPhoto?: Array<string>, sendNotificationToCustomer?: boolean, newFiles?: Array<any>, solution?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesOverviewModelPut(status, priority, servicePlace, caseId, caseCode, paymentWaiting, repairshopId, address, serviceTimeTick, messageToRepairer, messageToCustomer, repairerId, repairerConfirmed, sendEmailToCustomer, sendEmailToRepairer, errorTypeId, files, description, listPhoto, sendNotificationToCustomer, newFiles, solution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new case from SP
         * @param {ServiceProviderCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesPost(body?: ServiceProviderCasePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to the case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceProviderCaseDistanceToRepairshop>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to customer
         * @param {string} userId Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceProviderCaseDistanceToRepairshop>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceProviderCaseApi - factory interface
 * @export
 */
export const ServiceProviderCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceProviderCaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign to repairer
         * @param {ServiceProviderCaseOverViewAssignRepairer} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesAssignToRepairerPut(body?: ServiceProviderCaseOverViewAssignRepairer, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceProviderCasesAssignToRepairerPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign to repairshop
         * @param {ServiceProviderCaseOverViewAssignRepairshop} [body] model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesAssignToRepairshopPut(body?: ServiceProviderCaseOverViewAssignRepairshop, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceProviderCasesAssignToRepairshopPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves count total cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesCountTotalCasesGet(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiServiceProviderCasesCountTotalCasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves list customer and products belong to the customer
         * @param {number} page 
         * @param {number} limit 
         * @param {string} search Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesCustomerUserProductSelectGet(page: number, limit: number, search: string, options?: any): AxiosPromise<Array<ServiceProviderCaseCustomerUserProductSelect>> {
            return localVarFp.apiServiceProviderCasesCustomerUserProductSelectGet(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves case details by id
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesDetailsByCaseIdGet(caseId: number, options?: any): AxiosPromise<ServiceProviderCaseDetails> {
            return localVarFp.apiServiceProviderCasesDetailsByCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export excel
         * @param {ExportExcelRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesExportExcelPut(body?: ExportExcelRequest, options?: any): AxiosPromise<ExportExcelResponse> {
            return localVarFp.apiServiceProviderCasesExportExcelPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves case by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} status 
         * @param {any} servicePlace 
         * @param {number} repairShopId 
         * @param {any} typeOfDuration 
         * @param {string} orderBy 
         * @param {string} [search] 
         * @param {boolean} [isDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesGetByFilterGet(page: number, limit: number, status: any, servicePlace: any, repairShopId: number, typeOfDuration: any, orderBy: string, search?: string, isDesc?: boolean, options?: any): AxiosPromise<ServiceProviderCaseListResponse> {
            return localVarFp.apiServiceProviderCasesGetByFilterGet(page, limit, status, servicePlace, repairShopId, typeOfDuration, orderBy, search, isDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves List Open Case In Dashboard
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesListCaseDeadlineGet(body?: FilterCaseModel, options?: any): AxiosPromise<ServiceProviderCaseResponsePagingResult> {
            return localVarFp.apiServiceProviderCasesListCaseDeadlineGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves unassign list cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesNewUnAssignedCaseListGet(options?: any): AxiosPromise<Array<ServiceProviderCaseNewUnAssignedSelect>> {
            return localVarFp.apiServiceProviderCasesNewUnAssignedCaseListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves overview details of a specific case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesOverViewByCaseIdGet(caseId: number, options?: any): AxiosPromise<ServiceProviderCaseOverView> {
            return localVarFp.apiServiceProviderCasesOverViewByCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update overview model of an case
         * @param {any} status 
         * @param {any} priority 
         * @param {any} servicePlace 
         * @param {number} [caseId] 
         * @param {string} [caseCode] 
         * @param {boolean} [paymentWaiting] 
         * @param {number} [repairshopId] 
         * @param {string} [address] 
         * @param {number} [serviceTimeTick] 
         * @param {Array<MessageDetails>} [messageToRepairer] 
         * @param {Array<MessageDetails>} [messageToCustomer] 
         * @param {string} [repairerId] 
         * @param {boolean} [repairerConfirmed] 
         * @param {boolean} [sendEmailToCustomer] 
         * @param {boolean} [sendEmailToRepairer] 
         * @param {number} [errorTypeId] 
         * @param {Array<string>} [files] 
         * @param {string} [description] 
         * @param {Array<string>} [listPhoto] 
         * @param {boolean} [sendNotificationToCustomer] 
         * @param {Array<any>} [newFiles] 
         * @param {string} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesOverviewModelPut(status: any, priority: any, servicePlace: any, caseId?: number, caseCode?: string, paymentWaiting?: boolean, repairshopId?: number, address?: string, serviceTimeTick?: number, messageToRepairer?: Array<MessageDetails>, messageToCustomer?: Array<MessageDetails>, repairerId?: string, repairerConfirmed?: boolean, sendEmailToCustomer?: boolean, sendEmailToRepairer?: boolean, errorTypeId?: number, files?: Array<string>, description?: string, listPhoto?: Array<string>, sendNotificationToCustomer?: boolean, newFiles?: Array<any>, solution?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceProviderCasesOverviewModelPut(status, priority, servicePlace, caseId, caseCode, paymentWaiting, repairshopId, address, serviceTimeTick, messageToRepairer, messageToCustomer, repairerId, repairerConfirmed, sendEmailToCustomer, sendEmailToRepairer, errorTypeId, files, description, listPhoto, sendNotificationToCustomer, newFiles, solution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new case from SP
         * @param {ServiceProviderCasePostPut} [body] case info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesPost(body?: ServiceProviderCasePostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceProviderCasesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to the case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<ServiceProviderCaseDistanceToRepairshop>> {
            return localVarFp.apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves repairshops include distance to customer
         * @param {string} userId Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId: string, options?: any): AxiosPromise<Array<ServiceProviderCaseDistanceToRepairshop>> {
            return localVarFp.apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceProviderCaseApi - object-oriented interface
 * @export
 * @class ServiceProviderCaseApi
 * @extends {BaseAPI}
 */
export class ServiceProviderCaseApi extends BaseAPI {
    /**
     * 
     * @summary Assign to repairer
     * @param {ServiceProviderCaseOverViewAssignRepairer} [body] model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesAssignToRepairerPut(body?: ServiceProviderCaseOverViewAssignRepairer, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesAssignToRepairerPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign to repairshop
     * @param {ServiceProviderCaseOverViewAssignRepairshop} [body] model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesAssignToRepairshopPut(body?: ServiceProviderCaseOverViewAssignRepairshop, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesAssignToRepairshopPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves count total cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesCountTotalCasesGet(options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesCountTotalCasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves list customer and products belong to the customer
     * @param {number} page 
     * @param {number} limit 
     * @param {string} search Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesCustomerUserProductSelectGet(page: number, limit: number, search: string, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesCustomerUserProductSelectGet(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves case details by id
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesDetailsByCaseIdGet(caseId: number, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesDetailsByCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export excel
     * @param {ExportExcelRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesExportExcelPut(body?: ExportExcelRequest, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesExportExcelPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves case by filter
     * @param {number} page 
     * @param {number} limit 
     * @param {any} status 
     * @param {any} servicePlace 
     * @param {number} repairShopId 
     * @param {any} typeOfDuration 
     * @param {string} orderBy 
     * @param {string} [search] 
     * @param {boolean} [isDesc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesGetByFilterGet(page: number, limit: number, status: any, servicePlace: any, repairShopId: number, typeOfDuration: any, orderBy: string, search?: string, isDesc?: boolean, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesGetByFilterGet(page, limit, status, servicePlace, repairShopId, typeOfDuration, orderBy, search, isDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves List Open Case In Dashboard
     * @param {FilterCaseModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesListCaseDeadlineGet(body?: FilterCaseModel, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesListCaseDeadlineGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves unassign list cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesNewUnAssignedCaseListGet(options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesNewUnAssignedCaseListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves overview details of a specific case
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesOverViewByCaseIdGet(caseId: number, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesOverViewByCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update overview model of an case
     * @param {any} status 
     * @param {any} priority 
     * @param {any} servicePlace 
     * @param {number} [caseId] 
     * @param {string} [caseCode] 
     * @param {boolean} [paymentWaiting] 
     * @param {number} [repairshopId] 
     * @param {string} [address] 
     * @param {number} [serviceTimeTick] 
     * @param {Array<MessageDetails>} [messageToRepairer] 
     * @param {Array<MessageDetails>} [messageToCustomer] 
     * @param {string} [repairerId] 
     * @param {boolean} [repairerConfirmed] 
     * @param {boolean} [sendEmailToCustomer] 
     * @param {boolean} [sendEmailToRepairer] 
     * @param {number} [errorTypeId] 
     * @param {Array<string>} [files] 
     * @param {string} [description] 
     * @param {Array<string>} [listPhoto] 
     * @param {boolean} [sendNotificationToCustomer] 
     * @param {Array<any>} [newFiles] 
     * @param {string} [solution] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesOverviewModelPut(status: any, priority: any, servicePlace: any, caseId?: number, caseCode?: string, paymentWaiting?: boolean, repairshopId?: number, address?: string, serviceTimeTick?: number, messageToRepairer?: Array<MessageDetails>, messageToCustomer?: Array<MessageDetails>, repairerId?: string, repairerConfirmed?: boolean, sendEmailToCustomer?: boolean, sendEmailToRepairer?: boolean, errorTypeId?: number, files?: Array<string>, description?: string, listPhoto?: Array<string>, sendNotificationToCustomer?: boolean, newFiles?: Array<any>, solution?: string, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesOverviewModelPut(status, priority, servicePlace, caseId, caseCode, paymentWaiting, repairshopId, address, serviceTimeTick, messageToRepairer, messageToCustomer, repairerId, repairerConfirmed, sendEmailToCustomer, sendEmailToRepairer, errorTypeId, files, description, listPhoto, sendNotificationToCustomer, newFiles, solution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new case from SP
     * @param {ServiceProviderCasePostPut} [body] case info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesPost(body?: ServiceProviderCasePostPut, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves repairshops include distance to the case
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId: number, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesRepairshopDistanceByCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves repairshops include distance to customer
     * @param {string} userId Must check \&quot;Send empty value\&quot; or Swagger passes a comma for empty values otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderCaseApi
     */
    public apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId: string, options?: AxiosRequestConfig) {
        return ServiceProviderCaseApiFp(this.configuration).apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}
