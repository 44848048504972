import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  title?: string
  message: string
  yesText: string
  noText?: string
  form?: React.ReactElement
  yesBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  onClose: (yes?: boolean) => void
} & DialogProps
export default function DialogAlertModal({
  title,
  message,
  form,
  yesText,
  noText,
  yesBtnColor,
  open,
  onClose,
}: Props) {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{title ?? t('00_06_warning')}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
        {form ?? form}
      </DialogContent>
      <DialogActions>
        {noText && (
          <Button onClick={() => onClose()} color='secondary'>
            {noText}
          </Button>
        )}
        <Button onClick={() => onClose(true)} color={yesBtnColor ?? 'primary'} variant='contained'>
          {yesText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
