import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: 'ugyldig værdi',
    required: 'Dette er påkrævet',
    oneOf: 'Skal være en af følgende: ${values}',
    notOneOf: 'Disse er ikke tilladt: ${values}',
    notType: 'Skal være en ${type}-type',
    defined: 'Dette er påkrævet',
  },
  string: {
    length: 'Skal være nøjagtigt ${length} tegn',
    min: 'Skal være mindst ${min} tegn',
    max: 'Må ikke være mere end ${max} tegn',
    matches: 'Skal matche følgende: "${regex}"',
    email: 'Skal være en gyldig e-mail',
    url: 'Skal være en gyldig URL',
    uuid: 'Skal være et gyldigt UUID',
    trim: 'Kan ikke indeholde mellemrum før eller efter',
    lowercase: 'skal være små bogstaver',
    uppercase: 'Skal være store bogstaver',
  },
  number: {
    min: 'Skal være større end eller lig med ${min}',
    max: 'Skal være mindre end eller lig med ${max}',
    lessThan: 'Skal være mindre end ${less}',
    moreThan: 'Skal være større end ${more}',
    positive: 'Skal være et positivt tal',
    negative: 'Skal være et negativt tal',
    integer: 'Skal være et tal',
  },
  date: {
    min: 'Skal være senere end ${min}',
    max: 'Skal være før ${max}',
  },
  boolean: {
    isValue: 'Skal være ${value}',
  },
  object: {
    noUnknown: 'Ukendt nøgle ${ukendt}',
  },
  array: {
    min: 'Skal have mindst ${min} varer',
    max: 'Kan ikke have mere end ${max} varer',
    length: 'Skal have ${length} varer',
  },
})
export default yup
