import * as React from 'react'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const StyledDataGrid = styled((props: DataGridProps) => {
  const { t } = useTranslation()
  return (
    <DataGrid
      headerHeight={44}
      rowHeight={44}
      localeText={{ noRowsLabel: t('00_06_no_data_des') }}
      rowsPerPageOptions={[10]}
      {...props}
    />
  )
})(({ theme }) => ({
  border: 0,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  color: theme.palette.text.primary,
  '& .MuiDataGrid-main > div:first-of-type': {
    zIndex: 1,
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottomColor: theme.palette.divider,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  '& .MuiDataGrid-footerContainer': {
    borderTopWidth: 0,
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
}))
export default StyledDataGrid
