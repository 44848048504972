import { Avatar, Box, Container, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { AppBar, Icon, Loading } from '../../components'
import { NotificationApi } from '../../services/Swagger/Services'
import { useQuery } from '@tanstack/react-query'
import { DATE_FORMAT_FULL } from '../../components/DatePicker/DatePicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../theme'
import { getTextAvatar } from '../../services/Helper'

function Notification() {
  const { data, isLoading } = useQuery(['/api/customerCases/userProductSelect'], async () => {
    const res = await new NotificationApi().apiNotificationsMyNotificationsGet()
    return res.data
  })
  const read = (id: number) => {
    new NotificationApi().apiNotificationsReadNotificationByNotificationIdPut(id)
  }
  const { t } = useTranslation()
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('notification')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'md'}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            my: 6,
          }}
        >
          <Box sx={{ width: 30 }}>
            <Link to='/'>
              <Icon name='icon-arrow-left' color='white' />
            </Link>
          </Box>
          <Box>
            <Typography variant='h3'>{t('notification')}</Typography>
          </Box>
          <Box sx={{ width: 30 }}></Box>
        </Stack>
        {isLoading && <Loading />}
        {!isLoading && (
          <Box>
            {data &&
              data?.length > 0 &&
              data?.map((noti) => {
                return (
                  <Link
                    key={noti.notificationId}
                    onClick={() => read(noti.notificationId ?? 0)}
                    to={`/ticket?id=${noti.caseId}`}
                  >
                    <Box className='boxNotify'>
                      {noti.avatar ? (
                        <img src={noti.avatar} alt='' />
                      ) : (
                        <Avatar sx={{ bgcolor: noti.color }} aria-label='recipe'>
                          {getTextAvatar(noti.data?.customerName ?? '')}
                        </Avatar>
                      )}
                      <Box>
                        <Typography
                          sx={{ color: 'white', fontWeight: noti.isRead ? 400 : 600 }}
                          display='block'
                        >
                          {noti.content}
                        </Typography>
                        <Typography sx={{ lineHeight: 1.5 }} variant='caption' display='block'>
                          {moment.unix(noti.createdDate ?? 0).format(DATE_FORMAT_FULL)}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                )
              })}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default Notification
