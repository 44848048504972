/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ECaseStatus {
    UnSent = 'UnSent',
    NewUnAssigned = 'NewUnAssigned',
    Pending = 'Pending',
    Repairing = 'Repairing',
    Delayed = 'Delayed',
    PaymentWaiting = 'PaymentWaiting',
    Finished = 'Finished',
    Returned = 'Returned',
    Received = 'Received',
    Closed = 'Closed',
    All = 'All'
}


