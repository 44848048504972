/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppleUserData } from '../models';
// @ts-ignore
import { CustLoginDto } from '../models';
// @ts-ignore
import { LoginFacebookDto } from '../models';
// @ts-ignore
import { LoginGoogleDto } from '../models';
// @ts-ignore
import { LoginResponseDto } from '../models';
// @ts-ignore
import { ServiceProviderLoginDto } from '../models';
// @ts-ignore
import { SocialUserData } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login with normal customer user and repairman( from the app)
         * @param {CustLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsApploginPost: async (body?: CustLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/applogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {AppleUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginApplePost: async (body?: AppleUserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/loginApple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with facebook account
         * @param {LoginFacebookDto} [body] Facebook account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginFacebookPost: async (body?: LoginFacebookDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/loginFacebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with google account
         * @param {LoginGoogleDto} [body] Google account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginGooglePost: async (body?: LoginGoogleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/loginGoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {SocialUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginSocialPost: async (body?: SocialUserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/loginSocial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLogoutDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout current with device token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLogoutWithTokenByDeviceTokenDelete: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiAuthsLogoutWithTokenByDeviceTokenDelete', 'deviceToken', deviceToken)
            const localVarPath = `/api/auths/logoutWithToken/{deviceToken}`
                .replace(`{${"deviceToken"}}`, encodeURIComponent(String(deviceToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login for service provider/admin(web)
         * @param {ServiceProviderLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsServiceProviderLoginPost: async (body?: ServiceProviderLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auths/serviceProviderLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login with normal customer user and repairman( from the app)
         * @param {CustLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsApploginPost(body?: CustLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsApploginPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {AppleUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLoginApplePost(body?: AppleUserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLoginApplePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with facebook account
         * @param {LoginFacebookDto} [body] Facebook account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLoginFacebookPost(body?: LoginFacebookDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLoginFacebookPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with google account
         * @param {LoginGoogleDto} [body] Google account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLoginGooglePost(body?: LoginGoogleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLoginGooglePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {SocialUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLoginSocialPost(body?: SocialUserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLoginSocialPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLogoutDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLogoutDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout current with device token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login for service provider/admin(web)
         * @param {ServiceProviderLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthsServiceProviderLoginPost(body?: ServiceProviderLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthsServiceProviderLoginPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login with normal customer user and repairman( from the app)
         * @param {CustLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsApploginPost(body?: CustLoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsApploginPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {AppleUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginApplePost(body?: AppleUserData, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsLoginApplePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with facebook account
         * @param {LoginFacebookDto} [body] Facebook account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginFacebookPost(body?: LoginFacebookDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsLoginFacebookPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with google account
         * @param {LoginGoogleDto} [body] Google account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginGooglePost(body?: LoginGoogleDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsLoginGooglePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with Apple account
         * @param {SocialUserData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLoginSocialPost(body?: SocialUserData, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsLoginSocialPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLogoutDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthsLogoutDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout current with device token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login for service provider/admin(web)
         * @param {ServiceProviderLoginDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthsServiceProviderLoginPost(body?: ServiceProviderLoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.apiAuthsServiceProviderLoginPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login with normal customer user and repairman( from the app)
     * @param {CustLoginDto} [body] user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsApploginPost(body?: CustLoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsApploginPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with Apple account
     * @param {AppleUserData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLoginApplePost(body?: AppleUserData, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLoginApplePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with facebook account
     * @param {LoginFacebookDto} [body] Facebook account info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLoginFacebookPost(body?: LoginFacebookDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLoginFacebookPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with google account
     * @param {LoginGoogleDto} [body] Google account info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLoginGooglePost(body?: LoginGoogleDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLoginGooglePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with Apple account
     * @param {SocialUserData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLoginSocialPost(body?: SocialUserData, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLoginSocialPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLogoutDelete(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLogoutDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout current with device token
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsLogoutWithTokenByDeviceTokenDelete(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login for service provider/admin(web)
     * @param {ServiceProviderLoginDto} [body] user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthsServiceProviderLoginPost(body?: ServiceProviderLoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthsServiceProviderLoginPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
