/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppUserListResponse } from '../models';
// @ts-ignore
import { AppUserPost } from '../models';
// @ts-ignore
import { ChangePasswordDto } from '../models';
// @ts-ignore
import { ChangePasswordDtoFromSP } from '../models';
// @ts-ignore
import { CustomerPostPut } from '../models';
// @ts-ignore
import { CustomerProfile } from '../models';
// @ts-ignore
import { CustomerProfileUpdate } from '../models';
// @ts-ignore
import { RegisterDto } from '../models';
// @ts-ignore
import { RegisterResponseDto } from '../models';
// @ts-ignore
import { ResetPasswordConfirmDto } from '../models';
// @ts-ignore
import { UserProfile } from '../models';
// @ts-ignore
import { UserProfileResponse } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific user(includes customer) by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByEmailDelete: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountsByEmailDelete', 'email', email)
            const localVarPath = `/api/accounts/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByEmailMarkIsDeleted: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountsByEmailMarkIsDeleted', 'email', email)
            const localVarPath = `/api/accounts/delete/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} roleId All&#x3D;-1, Admin &#x3D; 0,  ServiceProvider &#x3D; 1, RepairMan &#x3D; 2
         * @param {any} status All&#x3D;-1, Activated &#x3D; 0, Deactivated &#x3D; 1,Deleted &#x3D; 2
         * @param {string} [search] name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByPageByLimitByRoleIdByStatusGet: async (page: number, limit: number, roleId: any, status: any, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiAccountsByPageByLimitByRoleIdByStatusGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiAccountsByPageByLimitByRoleIdByStatusGet', 'limit', limit)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiAccountsByPageByLimitByRoleIdByStatusGet', 'roleId', roleId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiAccountsByPageByLimitByRoleIdByStatusGet', 'status', status)
            const localVarPath = `/api/accounts/{page}/{limit}/{roleId}/{status}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change password from Sp
         * @param {ChangePasswordDtoFromSP} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsChangepwFromSPPut: async (body?: ChangePasswordDtoFromSP, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/changepwFromSP`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} [body] Change password model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsChangepwPost: async (body?: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/changepw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsCustomerDetailsByEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountsCustomerDetailsByEmailGet', 'email', email)
            const localVarPath = `/api/accounts/customerDetails/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves curent customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsCustomerProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/customerProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsDeleteManyDelete: async (body?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/DeleteMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user info from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsDetailsByEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountsDetailsByEmailGet', 'email', email)
            const localVarPath = `/api/accounts/details/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsGetAllEmailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/GetAllEmails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsNewCustomerPost: async (body?: CustomerPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/newCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsPost: async (body?: AppUserPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existed user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsPut: async (body?: AppUserPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Re call google api to get LatLng from address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsReCallGeoLocationPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/reCallGeoLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register new account: customer
         * @param {RegisterDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsRegisterPost: async (body?: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetAvatarPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/resetAvatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password
         * @param {string} email email info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetpwByEmailPost: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountsResetpwByEmailPost', 'email', email)
            const localVarPath = `/api/accounts/resetpw/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm to reset password
         * @param {ResetPasswordConfirmDto} [body] reset token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetpwConfirmPost: async (body?: ResetPasswordConfirmDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/resetpwConfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer profile, user for customer
         * @param {CustomerProfileUpdate} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateCustomerProfilePut: async (body?: CustomerProfileUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/updateCustomerProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existed customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateCustomerPut: async (body?: CustomerPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/updateCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user profile, user for service provider
         * @param {UserProfile} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateProfilePut: async (body?: UserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/updateProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves curent user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUserProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/userProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a specific user(includes customer) by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsByEmailDelete(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsByEmailDelete(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsByEmailMarkIsDeleted(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsByEmailMarkIsDeleted(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} roleId All&#x3D;-1, Admin &#x3D; 0,  ServiceProvider &#x3D; 1, RepairMan &#x3D; 2
         * @param {any} status All&#x3D;-1, Activated &#x3D; 0, Deactivated &#x3D; 1,Deleted &#x3D; 2
         * @param {string} [search] name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsByPageByLimitByRoleIdByStatusGet(page: number, limit: number, roleId: any, status: any, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsByPageByLimitByRoleIdByStatusGet(page, limit, roleId, status, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change password from Sp
         * @param {ChangePasswordDtoFromSP} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsChangepwFromSPPut(body?: ChangePasswordDtoFromSP, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsChangepwFromSPPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} [body] Change password model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsChangepwPost(body?: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsChangepwPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsCustomerDetailsByEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPostPut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsCustomerDetailsByEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves curent customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsCustomerProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsCustomerProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsDeleteManyDelete(body?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsDeleteManyDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user info from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsDetailsByEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUserPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsDetailsByEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsGetAllEmailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsGetAllEmailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsNewCustomerPost(body?: CustomerPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsNewCustomerPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add new user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsPost(body?: AppUserPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existed user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsPut(body?: AppUserPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Re call google api to get LatLng from address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsReCallGeoLocationPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsReCallGeoLocationPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register new account: customer
         * @param {RegisterDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsRegisterPost(body?: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsRegisterPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsResetAvatarPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsResetAvatarPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password
         * @param {string} email email info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsResetpwByEmailPost(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsResetpwByEmailPost(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm to reset password
         * @param {ResetPasswordConfirmDto} [body] reset token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsResetpwConfirmPost(body?: ResetPasswordConfirmDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsResetpwConfirmPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer profile, user for customer
         * @param {CustomerProfileUpdate} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsUpdateCustomerProfilePut(body?: CustomerProfileUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProfileUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsUpdateCustomerProfilePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existed customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsUpdateCustomerPut(body?: CustomerPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsUpdateCustomerPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user profile, user for service provider
         * @param {UserProfile} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsUpdateProfilePut(body?: UserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsUpdateProfilePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves curent user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountsUserProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountsUserProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a specific user(includes customer) by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByEmailDelete(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsByEmailDelete(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByEmailMarkIsDeleted(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsByEmailMarkIsDeleted(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} roleId All&#x3D;-1, Admin &#x3D; 0,  ServiceProvider &#x3D; 1, RepairMan &#x3D; 2
         * @param {any} status All&#x3D;-1, Activated &#x3D; 0, Deactivated &#x3D; 1,Deleted &#x3D; 2
         * @param {string} [search] name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsByPageByLimitByRoleIdByStatusGet(page: number, limit: number, roleId: any, status: any, search?: string, options?: any): AxiosPromise<AppUserListResponse> {
            return localVarFp.apiAccountsByPageByLimitByRoleIdByStatusGet(page, limit, roleId, status, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change password from Sp
         * @param {ChangePasswordDtoFromSP} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsChangepwFromSPPut(body?: ChangePasswordDtoFromSP, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsChangepwFromSPPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} [body] Change password model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsChangepwPost(body?: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsChangepwPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsCustomerDetailsByEmailGet(email: string, options?: any): AxiosPromise<CustomerPostPut> {
            return localVarFp.apiAccountsCustomerDetailsByEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves curent customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsCustomerProfileGet(options?: any): AxiosPromise<CustomerProfile> {
            return localVarFp.apiAccountsCustomerProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsDeleteManyDelete(body?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsDeleteManyDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user info from service provider for update
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsDetailsByEmailGet(email: string, options?: any): AxiosPromise<AppUserPost> {
            return localVarFp.apiAccountsDetailsByEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsGetAllEmailsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsGetAllEmailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsNewCustomerPost(body?: CustomerPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsNewCustomerPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add new user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsPost(body?: AppUserPost, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existed user for admin/service provider/repairman
         * @param {AppUserPost} [body] User info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsPut(body?: AppUserPost, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Re call google api to get LatLng from address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsReCallGeoLocationPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsReCallGeoLocationPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register new account: customer
         * @param {RegisterDto} [body] user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsRegisterPost(body?: RegisterDto, options?: any): AxiosPromise<RegisterResponseDto> {
            return localVarFp.apiAccountsRegisterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetAvatarPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsResetAvatarPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password
         * @param {string} email email info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetpwByEmailPost(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsResetpwByEmailPost(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm to reset password
         * @param {ResetPasswordConfirmDto} [body] reset token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsResetpwConfirmPost(body?: ResetPasswordConfirmDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsResetpwConfirmPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer profile, user for customer
         * @param {CustomerProfileUpdate} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateCustomerProfilePut(body?: CustomerProfileUpdate, options?: any): AxiosPromise<CustomerProfileUpdate> {
            return localVarFp.apiAccountsUpdateCustomerProfilePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existed customer from service provider
         * @param {CustomerPostPut} [body] cusstomer info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateCustomerPut(body?: CustomerPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsUpdateCustomerPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user profile, user for service provider
         * @param {UserProfile} [body] info need to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUpdateProfilePut(body?: UserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountsUpdateProfilePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves curent user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountsUserProfileGet(options?: any): AxiosPromise<UserProfileResponse> {
            return localVarFp.apiAccountsUserProfileGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific user(includes customer) by email
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsByEmailDelete(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsByEmailDelete(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsByEmailMarkIsDeleted(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsByEmailMarkIsDeleted(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by filter
     * @param {number} page 
     * @param {number} limit 
     * @param {any} roleId All&#x3D;-1, Admin &#x3D; 0,  ServiceProvider &#x3D; 1, RepairMan &#x3D; 2
     * @param {any} status All&#x3D;-1, Activated &#x3D; 0, Deactivated &#x3D; 1,Deleted &#x3D; 2
     * @param {string} [search] name or email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsByPageByLimitByRoleIdByStatusGet(page: number, limit: number, roleId: any, status: any, search?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsByPageByLimitByRoleIdByStatusGet(page, limit, roleId, status, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change password from Sp
     * @param {ChangePasswordDtoFromSP} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsChangepwFromSPPut(body?: ChangePasswordDtoFromSP, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsChangepwFromSPPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change password
     * @param {ChangePasswordDto} [body] Change password model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsChangepwPost(body?: ChangePasswordDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsChangepwPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer from service provider for update
     * @param {string} email email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsCustomerDetailsByEmailGet(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsCustomerDetailsByEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves curent customer profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsCustomerProfileGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsCustomerProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsDeleteManyDelete(body?: Array<string>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsDeleteManyDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user info from service provider for update
     * @param {string} email email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsDetailsByEmailGet(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsDetailsByEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsGetAllEmailsGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsGetAllEmailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new customer from service provider
     * @param {CustomerPostPut} [body] cusstomer info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsNewCustomerPost(body?: CustomerPostPut, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsNewCustomerPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add new user for admin/service provider/repairman
     * @param {AppUserPost} [body] User info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsPost(body?: AppUserPost, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existed user for admin/service provider/repairman
     * @param {AppUserPost} [body] User info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsPut(body?: AppUserPost, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Re call google api to get LatLng from address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsReCallGeoLocationPost(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsReCallGeoLocationPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register new account: customer
     * @param {RegisterDto} [body] user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsRegisterPost(body?: RegisterDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsRegisterPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsResetAvatarPost(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsResetAvatarPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password
     * @param {string} email email info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsResetpwByEmailPost(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsResetpwByEmailPost(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm to reset password
     * @param {ResetPasswordConfirmDto} [body] reset token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsResetpwConfirmPost(body?: ResetPasswordConfirmDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsResetpwConfirmPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer profile, user for customer
     * @param {CustomerProfileUpdate} [body] info need to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsUpdateCustomerProfilePut(body?: CustomerProfileUpdate, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsUpdateCustomerProfilePut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existed customer from service provider
     * @param {CustomerPostPut} [body] cusstomer info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsUpdateCustomerPut(body?: CustomerPostPut, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsUpdateCustomerPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user profile, user for service provider
     * @param {UserProfile} [body] info need to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsUpdateProfilePut(body?: UserProfile, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsUpdateProfilePut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves curent user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountsUserProfileGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountsUserProfileGet(options).then((request) => request(this.axios, this.basePath));
    }
}
