import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { GoogleOAuthProvider } from '@react-oauth/google'
import i18next from 'i18next'
import theme from './theme'
import './services/api'
import './services/Translations'
import { Provider } from 'react-redux'
import { Store } from './redux/index'

initializeIcons()

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={Store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OATH ?? ''}>
              <BrowserRouter>
                <HelmetProvider>
                  <CssBaseline />
                  <App />
                </HelmetProvider>
              </BrowserRouter>
            </GoogleOAuthProvider>
          </LocalizationProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
