import { AnyAction, configureStore, Slice } from '@reduxjs/toolkit'
import { ConfirmDialogProps } from './ConfirmDialogSlice'
import { ConfirmDialogSlice, SnackbarSlice } from '.'
import { SnackbarProps } from './SnackbarSlice'
export interface StoreRedux {
  ConfirmDialog: Slice<ConfirmDialogProps, AnyAction>
  Snackbar: Slice<SnackbarProps, AnyAction>
}
export default configureStore({
  reducer: {
    ConfirmDialog: ConfirmDialogSlice,
    Snackbar: SnackbarSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
