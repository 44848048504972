/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProductBrandCategorySelect } from '../models';
// @ts-ignore
import { ProductListResponse } from '../models';
// @ts-ignore
import { ProductPostPut } from '../models';
// @ts-ignore
import { ProductResponse } from '../models';
// @ts-ignore
import { ServiceProviderCaseUserProduct } from '../models';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Brand category select to create new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsBrandCategorySelectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/brandCategorySelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves products by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {number} brandId All&#x3D;-1
         * @param {number} categoryId All&#x3D;-1
         * @param {string} [search] product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByPageByLimitByBrandIdByCategoryIdGet: async (page: number, limit: number, brandId: number, categoryId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiProductsByPageByLimitByBrandIdByCategoryIdGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiProductsByPageByLimitByBrandIdByCategoryIdGet', 'limit', limit)
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiProductsByPageByLimitByBrandIdByCategoryIdGet', 'brandId', brandId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('apiProductsByPageByLimitByBrandIdByCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/api/products/{page}/{limit}/{brandId}/{categoryId}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)))
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdDelete: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductsByProductIdDelete', 'productId', productId)
            const localVarPath = `/api/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductsByProductIdGet', 'productId', productId)
            const localVarPath = `/api/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existed product by productId
         * @param {number} productId productId
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdPut: async (productId: number, body?: ProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductsByProductIdPut', 'productId', productId)
            const localVarPath = `/api/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View bike detai by user product id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByUserProductIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsByUserProductIdGet', 'id', id)
            const localVarPath = `/api/products/GetByUserProductId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new product
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost: async (body?: ProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new product createEgorepBike
         * @param {ProductPostPut} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPostEgorepBike: async (body?: ProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/createEgorepBike`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsTmpUpdateItemNameGet: async (input?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/tmpUpdateItemName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Brand category select to create new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsBrandCategorySelectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBrandCategorySelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsBrandCategorySelectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves products by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {number} brandId All&#x3D;-1
         * @param {number} categoryId All&#x3D;-1
         * @param {string} [search] product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsByPageByLimitByBrandIdByCategoryIdGet(page: number, limit: number, brandId: number, categoryId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsByPageByLimitByBrandIdByCategoryIdGet(page, limit, brandId, categoryId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsByProductIdDelete(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsByProductIdDelete(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsByProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsByProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existed product by productId
         * @param {number} productId productId
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsByProductIdPut(productId: number, body?: ProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsByProductIdPut(productId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View bike detai by user product id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsByUserProductIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseUserProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsByUserProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new product
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsPost(body?: ProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new product createEgorepBike
         * @param {ProductPostPut} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsPostEgorepBike(body?: ProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsPostEgorepBike(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsTmpUpdateItemNameGet(input?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsTmpUpdateItemNameGet(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Brand category select to create new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsBrandCategorySelectGet(options?: any): AxiosPromise<ProductBrandCategorySelect> {
            return localVarFp.apiProductsBrandCategorySelectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves products by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {number} brandId All&#x3D;-1
         * @param {number} categoryId All&#x3D;-1
         * @param {string} [search] product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByPageByLimitByBrandIdByCategoryIdGet(page: number, limit: number, brandId: number, categoryId: number, search?: string, options?: any): AxiosPromise<ProductListResponse> {
            return localVarFp.apiProductsByPageByLimitByBrandIdByCategoryIdGet(page, limit, brandId, categoryId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdDelete(productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsByProductIdDelete(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a specific product by productId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdGet(productId: number, options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.apiProductsByProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existed product by productId
         * @param {number} productId productId
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByProductIdPut(productId: number, body?: ProductPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsByProductIdPut(productId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View bike detai by user product id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsByUserProductIdGet(id: number, options?: any): AxiosPromise<ServiceProviderCaseUserProduct> {
            return localVarFp.apiProductsByUserProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new product
         * @param {ProductPostPut} [body] product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost(body?: ProductPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new product createEgorepBike
         * @param {ProductPostPut} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPostEgorepBike(body?: ProductPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsPostEgorepBike(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsTmpUpdateItemNameGet(input?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsTmpUpdateItemNameGet(input, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Get Brand category select to create new product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsBrandCategorySelectGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsBrandCategorySelectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves products by filter
     * @param {number} page 
     * @param {number} limit 
     * @param {number} brandId All&#x3D;-1
     * @param {number} categoryId All&#x3D;-1
     * @param {string} [search] product name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsByPageByLimitByBrandIdByCategoryIdGet(page: number, limit: number, brandId: number, categoryId: number, search?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsByPageByLimitByBrandIdByCategoryIdGet(page, limit, brandId, categoryId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific product by productId
     * @param {number} productId productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsByProductIdDelete(productId: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsByProductIdDelete(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a specific product by productId
     * @param {number} productId productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsByProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsByProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existed product by productId
     * @param {number} productId productId
     * @param {ProductPostPut} [body] product info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsByProductIdPut(productId: number, body?: ProductPostPut, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsByProductIdPut(productId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View bike detai by user product id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsByUserProductIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsByUserProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new product
     * @param {ProductPostPut} [body] product info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsPost(body?: ProductPostPut, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new product createEgorepBike
     * @param {ProductPostPut} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsPostEgorepBike(body?: ProductPostPut, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsPostEgorepBike(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductsTmpUpdateItemNameGet(input?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductsTmpUpdateItemNameGet(input, options).then((request) => request(this.axios, this.basePath));
    }
}
