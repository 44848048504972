/* eslint-disable quotes */
export default {
  save: 'Save',
  done: 'Done',
  dimiss: 'Dimiss',
  cancel: 'Cancel',
  continue: 'Continue',
  select: 'Select',
  noData: 'No data found',
  selectAnOption: 'Select an option',
  welcome: 'Landsdækkende cykelservice',
  login: 'Log ind',
  signUp: 'Sign up',
  signUpSucessTitle: 'Successfully!',
  signUpSucessDesc: 'Welcome to Cykelmaker, you can login now',
  signOut: 'Sign out',
  forgotPassword: 'Forgot password',
  forgotPasswordDes: 'Enter your email below',
  forgotSucessTitle: 'Successfully!',
  forgotSucessDesc:
    'We have sent reset link into your email. Please check your email to change your password.',
  resetPassword: 'Reset password',
  resetPasswordTitle: 'Please enter your new password to complete reset your password',
  backToLogin: 'Back to Login',
  signUpTitle: 'Welcome to Cykelmaker',
  signUpDesc: 'Sign up to your account',
  userProfile: 'User profile',
  editProfile: 'Edit profile',
  deteleAccount: 'GDPR',
  deteleAccountBtnDes: 'Delete my account',
  deteleAccountBtn: 'Delete',
  deteleWarningTitle: 'Are you sure?',
  deteleWarningDesc:
    'This action will remove your account and data from our app forever, all of your bikes, tickets will remove and can not restore.',
  deteleWarningDesc2: 'Make sure you want to delete your account',
  change: 'Change',
  email: 'Email address',
  fullname: 'Full name',
  password: 'Password',
  rePassword: 'Re-password',
  yourContact: 'Your contact',
  address: 'Address',
  addressPlaceholder: 'Enter an address',
  postalCode: 'Postal code',
  city: 'City',
  country: 'Country',
  phoneNumber: 'Phone number',
  howWeWillContactYou: 'How we contact you?',
  language: 'Language',
  welcomeBack: 'Welcome back',
  getStarted: 'Get started with Registering \n your new bike now',
  myBikes: 'My bikes',
  myTickets: 'My tickets',
  registerABike: 'Register a bike',
  createTicketDesc: 'Tell us what is your bike \n problem',
  createATicket: 'Create a ticket',
  selectABike: 'Select a bike',
  errorType: 'Error type',
  subject: 'Subject',
  description: 'Description',
  placeOfService: 'Where do you want to do the service?',
  repairShop: 'Choose the repair shop',

  lastUpdated: 'Last updated',
  brand: 'Brand',
  category: 'Category',
  year: 'Year',
  model: 'Model',
  frameNumber: 'Frame number',
  frameNumbersPhoto: "Frame number's photo",
  batteryNumber: 'Battery number',
  batteryNumberDescription: 'The battery number is underneith the barcode on your battery',
  billInformation: 'Bill information',
  number: 'Number',
  billNumber: 'Bill number',
  purchasedDate: 'Purchased date',
  purchasedPlace: 'Purchased place',
  billsPhoto: "Bill's photo",
  registerBikeWarning:
    'Please verify that the frame number, battery number (if applicable) and date of purchase are registered correctly. A legible picture of the bill of purchase will reduce the case handling time.',
  bikeDetails: 'Bike details',
  bikeInfo: 'Bike info',
  warrantyUntil: 'Warranty until',
  tickets: 'Tickets',
  bikeShop: 'Bike shop',
  bikeShops: 'Bike shops',
  onlineShops: 'Online shops',
  overview: 'Overview',
  ticketInfo: 'Ticket info',
  payment: 'Payment',
  paynow: 'Pay now',
  ticketID: 'Ticket ID',
  ticketSubject: 'Ticket subject',
  submittedDate: 'Submitted date',
  bike: 'Bike',
  servicePlace: 'Service place',
  status: 'Status',
  message: 'Message',
  sendMessage: 'Send message',
  typeAMessage: 'Type a message',
  messageDescription: 'Message from repairers or repair shop',
  // Ticket Status Text
  UnSent: 'UnSent',
  NewUnAssigned: 'NewUnAssigned',
  Pending: 'Pending',
  Repairing: 'Repairing',
  Delayed: 'Delayed',
  PaymentWaiting: 'Payment Waiting',
  Finished: 'Finished',
  Returned: 'Returned',
  Received: 'Received',
  Closed: 'Closed',
  All: 'All',
  AtCustomerSite: 'Customer Site',
  AtRepairshop: 'Repair shop',
  notification: 'Notification ',
  languages: {
    da: 'Danish',
    en: 'English',
  },
  contactTypes: {
    EmailNotification: 'Email & Notification',
    Email: 'Email',
    Notification: 'Notification',
  },
  changePassword: 'Change password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  updateProfile: 'Update profile',
  photoPermissionBtn: 'Request',
  photoPermissionTitle: 'ALLOW PHOTO PERMISSION',
  photoPermissionDes: 'Register your bike info or describe your bike problems',
  photoPermissionDes2:
    'Snap pictures of your bike frame number, battery number (if e-bike) and the bill',
  photoPermissionDes3: 'You can change this later in the Settings app',
  notificationPermissionBtn: 'Request',
  notificationPermissionTitle: 'ENABLE NOTIFICATIONS',
  notificationPermissionDes:
    'Select "ALLOW" to receive notifications on your bike support from Cykelmakker team',
  batteryNumberPhoto: 'Battery number photo',
  bikePhotos: 'Bike photos',
  orLoginWith: 'Or login with',
  billPdf: `Bill's document`,
}
