/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomerDashboard } from '../models';
// @ts-ignore
import { CustomerDetails } from '../models';
// @ts-ignore
import { CustomerListResponse } from '../models';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration AllTime&#x3D;-1,ThisYear &#x3D; 0, ThisMonth &#x3D; 1, Today&#x3D;2
         * @param {string} [search] Customer name/email/phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersByPageByLimitByTypeOfDurationGet: async (page: number, limit: number, typeOfDuration: any, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiCustomersByPageByLimitByTypeOfDurationGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiCustomersByPageByLimitByTypeOfDurationGet', 'limit', limit)
            // verify required parameter 'typeOfDuration' is not null or undefined
            assertParamExists('apiCustomersByPageByLimitByTypeOfDurationGet', 'typeOfDuration', typeOfDuration)
            const localVarPath = `/api/customers/{page}/{limit}/{typeOfDuration}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)))
                .replace(`{${"typeOfDuration"}}`, encodeURIComponent(String(typeOfDuration)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves dashboard of specific customer by current user(customer)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDashboardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customers/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves details of a specific customer by id
         * @param {number} customerId id of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDetailsByCustomerIdGet: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiCustomersDetailsByCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/api/customers/details/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves repairer\'s customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRepairerCustomersByPageByLimitGet: async (page: number, limit: number, typeOfDuration: any, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiRepairerCustomersByPageByLimitGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiRepairerCustomersByPageByLimitGet', 'limit', limit)
            // verify required parameter 'typeOfDuration' is not null or undefined
            assertParamExists('apiRepairerCustomersByPageByLimitGet', 'typeOfDuration', typeOfDuration)
            const localVarPath = `/api/customers/getRepairerCustomers/{page}/{limit}/{typeOfDuration}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)))
                .replace(`{${"typeOfDuration"}}`, encodeURIComponent(String(typeOfDuration)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration AllTime&#x3D;-1,ThisYear &#x3D; 0, ThisMonth &#x3D; 1, Today&#x3D;2
         * @param {string} [search] Customer name/email/phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersByPageByLimitByTypeOfDurationGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersByPageByLimitByTypeOfDurationGet(page, limit, typeOfDuration, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves dashboard of specific customer by current user(customer)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersDashboardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersDashboardGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves details of a specific customer by id
         * @param {number} customerId id of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersDetailsByCustomerIdGet(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersDetailsByCustomerIdGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves repairer\'s customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRepairerCustomersByPageByLimitGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRepairerCustomersByPageByLimitGet(page, limit, typeOfDuration, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration AllTime&#x3D;-1,ThisYear &#x3D; 0, ThisMonth &#x3D; 1, Today&#x3D;2
         * @param {string} [search] Customer name/email/phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersByPageByLimitByTypeOfDurationGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: any): AxiosPromise<CustomerListResponse> {
            return localVarFp.apiCustomersByPageByLimitByTypeOfDurationGet(page, limit, typeOfDuration, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves dashboard of specific customer by current user(customer)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDashboardGet(options?: any): AxiosPromise<CustomerDashboard> {
            return localVarFp.apiCustomersDashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves details of a specific customer by id
         * @param {number} customerId id of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDetailsByCustomerIdGet(customerId: number, options?: any): AxiosPromise<CustomerDetails> {
            return localVarFp.apiCustomersDetailsByCustomerIdGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves repairer\'s customers by filter
         * @param {number} page 
         * @param {number} limit 
         * @param {any} typeOfDuration 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRepairerCustomersByPageByLimitGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: any): AxiosPromise<CustomerListResponse> {
            return localVarFp.apiRepairerCustomersByPageByLimitGet(page, limit, typeOfDuration, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves customers by filter
     * @param {number} page 
     * @param {number} limit 
     * @param {any} typeOfDuration AllTime&#x3D;-1,ThisYear &#x3D; 0, ThisMonth &#x3D; 1, Today&#x3D;2
     * @param {string} [search] Customer name/email/phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomersByPageByLimitByTypeOfDurationGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomersByPageByLimitByTypeOfDurationGet(page, limit, typeOfDuration, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves dashboard of specific customer by current user(customer)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomersDashboardGet(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomersDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves details of a specific customer by id
     * @param {number} customerId id of customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomersDetailsByCustomerIdGet(customerId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomersDetailsByCustomerIdGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves repairer\'s customers by filter
     * @param {number} page 
     * @param {number} limit 
     * @param {any} typeOfDuration 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiRepairerCustomersByPageByLimitGet(page: number, limit: number, typeOfDuration: any, search?: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiRepairerCustomersByPageByLimitGet(page, limit, typeOfDuration, search, options).then((request) => request(this.axios, this.basePath));
    }
}
