/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MessageFilter } from '../models';
// @ts-ignore
import { MessagePostPut } from '../models';
// @ts-ignore
import { ServiceProviderCaseMessage } from '../models';
// @ts-ignore
import { ServiceProviderCaseResponsePagingResult } from '../models';
/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list Service Case
         * @param {MessageFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageGetListCasePost: async (body?: MessageFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/getListCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific message by messageId
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesByMessageIdDelete: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiMessagesByMessageIdDelete', 'messageId', messageId)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markStar Update message status to read
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCaseMarkStar: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiMessagesCaseMarkStar', 'caseId', caseId)
            const localVarPath = `/api/messages/markStar/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mark a message as unread
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCaseMarkUnread: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiMessagesCaseMarkUnread', 'messageId', messageId)
            const localVarPath = `/api/messages/markUnread/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCreateMessagTemplatePost: async (body?: MessagePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/createMessagTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get List Message By Case
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetListByCase: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiMessagesGetListByCase', 'caseId', caseId)
            const localVarPath = `/api/messages/GetListMessageByCase/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost: async (body?: MessagePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload new message file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostFile: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/uploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all messages of case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesReadAllMessagesByCaseIdPut: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiMessagesReadAllMessagesByCaseIdPut', 'caseId', caseId)
            const localVarPath = `/api/messages/readAllMessages/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update message status to read
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesReadMessagesByMessageIdPut: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiMessagesReadMessagesByMessageIdPut', 'messageId', messageId)
            const localVarPath = `/api/messages/readMessages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SetReadUserIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesSetReadUserIdsPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/setReadUserIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetAllTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetAllTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetAllTemplateByUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplateByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetAllTemplateByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list Service Case
         * @param {MessageFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageGetListCasePost(body?: MessageFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceProviderCaseResponsePagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageGetListCasePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific message by messageId
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesByMessageIdDelete(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesByMessageIdDelete(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markStar Update message status to read
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesCaseMarkStar(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesCaseMarkStar(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mark a message as unread
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesCaseMarkUnread(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesCaseMarkUnread(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesCreateMessagTemplatePost(body?: MessagePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesCreateMessagTemplatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get List Message By Case
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetListByCase(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceProviderCaseMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetListByCase(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPost(body?: MessagePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload new message file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPostFile(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPostFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all messages of case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesReadAllMessagesByCaseIdPut(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesReadAllMessagesByCaseIdPut(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update message status to read
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesReadMessagesByMessageIdPut(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesReadMessagesByMessageIdPut(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SetReadUserIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesSetReadUserIdsPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesSetReadUserIdsPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetAllTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetAllTemplateByUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplateByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplateByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list Service Case
         * @param {MessageFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageGetListCasePost(body?: MessageFilter, options?: any): AxiosPromise<ServiceProviderCaseResponsePagingResult> {
            return localVarFp.apiMessageGetListCasePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific message by messageId
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesByMessageIdDelete(messageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesByMessageIdDelete(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markStar Update message status to read
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCaseMarkStar(caseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesCaseMarkStar(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mark a message as unread
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCaseMarkUnread(messageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesCaseMarkUnread(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesCreateMessagTemplatePost(body?: MessagePostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesCreateMessagTemplatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get List Message By Case
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetListByCase(caseId: number, options?: any): AxiosPromise<Array<ServiceProviderCaseMessage>> {
            return localVarFp.apiMessagesGetListByCase(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new message
         * @param {MessagePostPut} [body] message info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost(body?: MessagePostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload new message file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostFile(file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiMessagesPostFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all messages of case
         * @param {number} caseId caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesReadAllMessagesByCaseIdPut(caseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesReadAllMessagesByCaseIdPut(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update message status to read
         * @param {number} messageId messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesReadMessagesByMessageIdPut(messageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesReadMessagesByMessageIdPut(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SetReadUserIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesSetReadUserIdsPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesSetReadUserIdsPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetAllTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplate(options?: any): AxiosPromise<void> {
            return localVarFp.getAllTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetAllTemplateByUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplateByUser(options?: any): AxiosPromise<void> {
            return localVarFp.getAllTemplateByUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Get list Service Case
     * @param {MessageFilter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageGetListCasePost(body?: MessageFilter, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageGetListCasePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific message by messageId
     * @param {number} messageId messageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesByMessageIdDelete(messageId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesByMessageIdDelete(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markStar Update message status to read
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesCaseMarkStar(caseId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesCaseMarkStar(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mark a message as unread
     * @param {number} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesCaseMarkUnread(messageId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesCaseMarkUnread(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new message
     * @param {MessagePostPut} [body] message info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesCreateMessagTemplatePost(body?: MessagePostPut, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesCreateMessagTemplatePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get List Message By Case
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesGetListByCase(caseId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesGetListByCase(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new message
     * @param {MessagePostPut} [body] message info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesPost(body?: MessagePostPut, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload new message file
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesPostFile(file?: any, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesPostFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all messages of case
     * @param {number} caseId caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesReadAllMessagesByCaseIdPut(caseId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesReadAllMessagesByCaseIdPut(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update message status to read
     * @param {number} messageId messageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesReadMessagesByMessageIdPut(messageId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesReadMessagesByMessageIdPut(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SetReadUserIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesSetReadUserIdsPut(options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesSetReadUserIdsPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetAllTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getAllTemplate(options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).getAllTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetAllTemplateByUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getAllTemplateByUser(options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).getAllTemplateByUser(options).then((request) => request(this.axios, this.basePath));
    }
}
