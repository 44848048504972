/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * CaseDisplayErrorApi - axios parameter creator
 * @export
 */
export const CaseDisplayErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Force popup to customer if don\'t confirm option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseDisplayErrorCheckExistDisplayErrorCaseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/case-display-error/CheckExistDisplayErrorCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {any} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmOption: async (caseId: number, option: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('customerConfirmOption', 'caseId', caseId)
            // verify required parameter 'option' is not null or undefined
            assertParamExists('customerConfirmOption', 'option', option)
            const localVarPath = `/api/case-display-error/CustomerConfirm/{caseId}/{option}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"option"}}`, encodeURIComponent(String(option)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send option to customer
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToCustomerDisplayErrorCase: async (caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('sendToCustomerDisplayErrorCase', 'caseId', caseId)
            const localVarPath = `/api/case-display-error/SendToCustomer/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} sendDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVoucherAndDevice: async (caseId: number, sendDate: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('sendVoucherAndDevice', 'caseId', caseId)
            // verify required parameter 'sendDate' is not null or undefined
            assertParamExists('sendVoucherAndDevice', 'sendDate', sendDate)
            const localVarPath = `/api/case-display-error/SendVoucherAndDevice/{caseId}/{sendDate}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"sendDate"}}`, encodeURIComponent(String(sendDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseDisplayErrorApi - functional programming interface
 * @export
 */
export const CaseDisplayErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseDisplayErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Force popup to customer if don\'t confirm option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {any} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerConfirmOption(caseId: number, option: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerConfirmOption(caseId, option, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send option to customer
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToCustomerDisplayErrorCase(caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToCustomerDisplayErrorCase(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} sendDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendVoucherAndDevice(caseId: number, sendDate: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendVoucherAndDevice(caseId, sendDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseDisplayErrorApi - factory interface
 * @export
 */
export const CaseDisplayErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseDisplayErrorApiFp(configuration)
    return {
        /**
         * 
         * @summary Force popup to customer if don\'t confirm option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {any} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerConfirmOption(caseId: number, option: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.customerConfirmOption(caseId, option, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send option to customer
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToCustomerDisplayErrorCase(caseId: number, options?: any): AxiosPromise<number> {
            return localVarFp.sendToCustomerDisplayErrorCase(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} sendDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVoucherAndDevice(caseId: number, sendDate: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendVoucherAndDevice(caseId, sendDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseDisplayErrorApi - object-oriented interface
 * @export
 * @class CaseDisplayErrorApi
 * @extends {BaseAPI}
 */
export class CaseDisplayErrorApi extends BaseAPI {
    /**
     * 
     * @summary Force popup to customer if don\'t confirm option
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseDisplayErrorApi
     */
    public apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options?: AxiosRequestConfig) {
        return CaseDisplayErrorApiFp(this.configuration).apiCaseDisplayErrorCheckExistDisplayErrorCaseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {any} option 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseDisplayErrorApi
     */
    public customerConfirmOption(caseId: number, option: any, options?: AxiosRequestConfig) {
        return CaseDisplayErrorApiFp(this.configuration).customerConfirmOption(caseId, option, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send option to customer
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseDisplayErrorApi
     */
    public sendToCustomerDisplayErrorCase(caseId: number, options?: AxiosRequestConfig) {
        return CaseDisplayErrorApiFp(this.configuration).sendToCustomerDisplayErrorCase(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} sendDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseDisplayErrorApi
     */
    public sendVoucherAndDevice(caseId: number, sendDate: number, options?: AxiosRequestConfig) {
        return CaseDisplayErrorApiFp(this.configuration).sendVoucherAndDevice(caseId, sendDate, options).then((request) => request(this.axios, this.basePath));
    }
}
