import { Box, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

function Home() {
  const { t } = useTranslation()

  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('welcome')}!</title>
      </Helmet>
      <Container maxWidth={'sm'}>
        <div
          style={{
            position: 'fixed',
            bottom: 30,
            right: 80,
            padding: 20,
          }}
        >
          <h3>
            Velkommen til vores chat support. Hvis du har nogle spørgsmål eller har brug for hjælp,
            sidder vi klar til at hjælpe. Vi vil besvare dine spørgsmål hurtigst muligt!
          </h3>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <img src='/arrow.svg' width={100} alt='arrow' />
          </div>
        </div>
      </Container>
    </Box>
  )
}

export default Home
