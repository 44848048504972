export enum FormCloseType {
  Save = 1,
  Close = 2,
}
export const MAP_AUTO_COMPLETE_CONFIG = {
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  language: 'da',
  options: {
    types: ['geocode'],
  },
}
