/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ERepairshopType {
    Repairshop = 'Repairshop',
    Bikeshop = 'Bikeshop',
    Both = 'Both',
    OnlineShop = 'OnlineShop'
}


