import { Alert, AlertTitle, Box, Button, Container, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { AppBar } from '../../components'
import { updateSnackbarState } from '../../redux'
import { AccountApi } from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import { useTranslation } from 'react-i18next'

const loginSchema = yup.object({
  email: yup.string().email().required(),
})

function Forgot() {
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [loginModel] = useState<{ email: string }>({
    email: '',
  })
  const onLoginSubmit = async (values: { email: string }) => {
    setIsSubmitting(true)
    try {
      await new AccountApi().apiAccountsResetpwByEmailPost(values.email)
      setIsSuccess(true)
    } catch (error: any) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Please try again!',
          type: 'error',
        } as any),
      )
    }
    setIsSubmitting(false)
  }
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('forgotPassword')}</title>
      </Helmet>
      <AppBar title={t('forgotPassword')} />
      <Container maxWidth={'sm'}>
        <Box sx={{ my: 12 }}>
          <Typography variant='h3' align='center'>
            {t('forgotPasswordDes')}
          </Typography>
        </Box>
        <Formik initialValues={loginModel} onSubmit={onLoginSubmit} validationSchema={loginSchema}>
          {({ handleChange, handleSubmit, values, errors }) => (
            <>
              {!isSuccess && (
                <>
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption'>{t('email')}</Typography>
                    <TextField
                      value={values.email}
                      onChange={handleChange('email')}
                      fullWidth
                      variant='outlined'
                    />
                    {errors.email && <Typography className='textError'>{errors.email}</Typography>}
                  </Box>
                  <Box sx={{ mb: 6 }}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      variant='contained'
                      onClick={() => handleSubmit()}
                    >
                      {t('resetPassword')}
                    </Button>
                  </Box>
                </>
              )}
              {isSuccess && (
                <>
                  <Alert severity='success' sx={{ mb: 8 }}>
                    <AlertTitle>{t('forgotSucessTitle')}</AlertTitle>
                    {t('forgotSucessDesc')}
                  </Alert>
                </>
              )}
              <Typography sx={{ color: Colors.primary, mb: 8 }} align='center'>
                <Link to='/auth/login'>{t('backToLogin')}</Link>
              </Typography>
            </>
          )}
        </Formik>
      </Container>
    </Box>
  )
}

export default Forgot
