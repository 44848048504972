import globalAxios from 'axios'
import Storage, { LOGIN_USER } from './Storage'
import { LoginResponseDto } from './Swagger/Services'

globalAxios.defaults.baseURL = process.env.REACT_APP_API_URL

const requestHandler = async (request: any) => {
  const storageToken: LoginResponseDto = Storage.getItem(LOGIN_USER)
  if (storageToken !== null) {
    request.headers.Authorization = `Bearer ${storageToken.token}`
  }
  return request
}

globalAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (requestError) => {
    return Promise.reject(requestError)
  },
)
globalAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export function callDeleteApi(url: string, data: any) {
  const storageToken: LoginResponseDto = Storage.getItem(LOGIN_USER)
  return globalAxios.delete(url, {
    headers: {
      Authorization: `Bearer ${storageToken.token}`,
    },
    data,
  })
}
