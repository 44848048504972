/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum EPaymentMethod {
    None = 'NONE',
    CreditCard = 'CREDIT_CARD',
    ApplePay = 'APPLE_PAY',
    AndroidPay = 'ANDROID_PAY',
    Paypal = 'PAYPAL',
    SamsungPay = 'SAMSUNG_PAY'
}


