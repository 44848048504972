/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FilterCaseModel } from '../models';
// @ts-ignore
import { UserProductBrandCategoryProductSelect } from '../models';
// @ts-ignore
import { UserProductDetails } from '../models';
// @ts-ignore
import { UserProductDetailsUpdate } from '../models';
// @ts-ignore
import { UserProductOverView } from '../models';
// @ts-ignore
import { UserProductPostPut } from '../models';
// @ts-ignore
import { UserProductPostPutPagingResult } from '../models';
/**
 * UserProductApi - axios parameter creator
 * @export
 */
export const UserProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateProductType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUpdateProductType', 'id', id)
            const localVarPath = `/api/userProducts/Type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductByIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserProductByIdGet', 'id', id)
            const localVarPath = `/api/userProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves list brand, category, product, repairshop select in order to create new user product
         * @param {boolean} [isGetHide] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsBrandCategoryProductSelectGet: async (isGetHide?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userProducts/brandCategoryProductSelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isGetHide !== undefined) {
                localVarQueryParameter['isGetHide'] = isGetHide;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsByUserProductIdDelete: async (userProductId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProductId' is not null or undefined
            assertParamExists('apiUserProductsByUserProductIdDelete', 'userProductId', userProductId)
            const localVarPath = `/api/userProducts/{userProductId}`
                .replace(`{${"userProductId"}}`, encodeURIComponent(String(userProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existed user product
         * @param {number} userProductId userProductId
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsByUserProductIdPut: async (userProductId: number, body?: UserProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProductId' is not null or undefined
            assertParamExists('apiUserProductsByUserProductIdPut', 'userProductId', userProductId)
            const localVarPath = `/api/userProducts/{userProductId}`
                .replace(`{${"userProductId"}}`, encodeURIComponent(String(userProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsDetailsByUserProductIdGet: async (userProductId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProductId' is not null or undefined
            assertParamExists('apiUserProductsDetailsByUserProductIdGet', 'userProductId', userProductId)
            const localVarPath = `/api/userProducts/details/{userProductId}`
                .replace(`{${"userProductId"}}`, encodeURIComponent(String(userProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId detailsUpdate
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsDetailsUpdateByUserProductIdGet: async (userProductId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProductId' is not null or undefined
            assertParamExists('apiUserProductsDetailsUpdateByUserProductIdGet', 'userProductId', userProductId)
            const localVarPath = `/api/userProducts/detailsUpdate/{userProductId}`
                .replace(`{${"userProductId"}}`, encodeURIComponent(String(userProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves  user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsMyUserProductsGet: async (body?: FilterCaseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userProducts/myUserProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsMyUserProductsSearch: async (body?: FilterCaseModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userProducts/searchShopBike`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsPost: async (body?: UserProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productName 
         * @param {number} productBrandId 
         * @param {number} productCategoryId 
         * @param {number} productWarrantyPeriod 
         * @param {number} [customerId] 
         * @param {number} [productId] 
         * @param {number} [productProductId] 
         * @param {number} [productBrandBrandId] 
         * @param {string} [productBrandName] 
         * @param {boolean} [productBrandIsActive] 
         * @param {string} [productPhoto] 
         * @param {string} [productItemNumber] 
         * @param {number} [productType] 
         * @param {number} [productYear] 
         * @param {boolean} [productHidding] 
         * @param {number} [categoryId] 
         * @param {string} [frameNumber] 
         * @param {string} [serialNumber] 
         * @param {number} [billDateTick] 
         * @param {string} [billNumber] 
         * @param {number} [purchasedPlaceId] 
         * @param {any} [billPhoto] 
         * @param {Array<any>} [framePhotos] 
         * @param {Array<any>} [serialPhotos] 
         * @param {boolean} [isCancelled] 
         * @param {number} [userProductId] 
         * @param {string} [batteryNumber] 
         * @param {number} [type] 
         * @param {number} [bikeType] 
         * @param {string} [photoUrl] 
         * @param {number} [caseId] 
         * @param {boolean} [isHaveOpenCase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsPostWithForm: async (productName: string, productBrandId: number, productCategoryId: number, productWarrantyPeriod: number, customerId?: number, productId?: number, productProductId?: number, productBrandBrandId?: number, productBrandName?: string, productBrandIsActive?: boolean, productPhoto?: string, productItemNumber?: string, productType?: number, productYear?: number, productHidding?: boolean, categoryId?: number, frameNumber?: string, serialNumber?: string, billDateTick?: number, billNumber?: string, purchasedPlaceId?: number, billPhoto?: any, framePhotos?: Array<any>, serialPhotos?: Array<any>, isCancelled?: boolean, userProductId?: number, batteryNumber?: string, type?: number, bikeType?: number, photoUrl?: string, caseId?: number, isHaveOpenCase?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productName' is not null or undefined
            assertParamExists('apiUserProductsPostWithForm', 'productName', productName)
            // verify required parameter 'productBrandId' is not null or undefined
            assertParamExists('apiUserProductsPostWithForm', 'productBrandId', productBrandId)
            // verify required parameter 'productCategoryId' is not null or undefined
            assertParamExists('apiUserProductsPostWithForm', 'productCategoryId', productCategoryId)
            // verify required parameter 'productWarrantyPeriod' is not null or undefined
            assertParamExists('apiUserProductsPostWithForm', 'productWarrantyPeriod', productWarrantyPeriod)
            const localVarPath = `/api/userProducts/Form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (customerId !== undefined) { 
                localVarFormParams.append('CustomerId', customerId as any);
            }
    
            if (productId !== undefined) { 
                localVarFormParams.append('ProductId', productId as any);
            }
    
            if (productProductId !== undefined) { 
                localVarFormParams.append('Product.ProductId', productProductId as any);
            }
    
            if (productName !== undefined) { 
                localVarFormParams.append('Product.Name', productName as any);
            }
    
            if (productBrandId !== undefined) { 
                localVarFormParams.append('Product.BrandId', productBrandId as any);
            }
    
            if (productBrandBrandId !== undefined) { 
                localVarFormParams.append('Product.Brand.BrandId', productBrandBrandId as any);
            }
    
            if (productBrandName !== undefined) { 
                localVarFormParams.append('Product.Brand.Name', productBrandName as any);
            }
    
            if (productBrandIsActive !== undefined) { 
                localVarFormParams.append('Product.Brand.IsActive', productBrandIsActive as any);
            }
    
            if (productCategoryId !== undefined) { 
                localVarFormParams.append('Product.CategoryId', productCategoryId as any);
            }
    
            if (productPhoto !== undefined) { 
                localVarFormParams.append('Product.Photo', productPhoto as any);
            }
    
            if (productWarrantyPeriod !== undefined) { 
                localVarFormParams.append('Product.WarrantyPeriod', productWarrantyPeriod as any);
            }
    
            if (productItemNumber !== undefined) { 
                localVarFormParams.append('Product.ItemNumber', productItemNumber as any);
            }
    
            if (productType !== undefined) { 
                localVarFormParams.append('Product.Type', productType as any);
            }
    
            if (productYear !== undefined) { 
                localVarFormParams.append('Product.Year', productYear as any);
            }
    
            if (productHidding !== undefined) { 
                localVarFormParams.append('Product.Hidding', productHidding as any);
            }
    
            if (categoryId !== undefined) { 
                localVarFormParams.append('CategoryId', categoryId as any);
            }
    
            if (frameNumber !== undefined) { 
                localVarFormParams.append('FrameNumber', frameNumber as any);
            }
    
            if (serialNumber !== undefined) { 
                localVarFormParams.append('SerialNumber', serialNumber as any);
            }
    
            if (billDateTick !== undefined) { 
                localVarFormParams.append('BillDateTick', billDateTick as any);
            }
    
            if (billNumber !== undefined) { 
                localVarFormParams.append('BillNumber', billNumber as any);
            }
    
            if (purchasedPlaceId !== undefined) { 
                localVarFormParams.append('PurchasedPlaceId', purchasedPlaceId as any);
            }
    
            if (billPhoto !== undefined) { 
                localVarFormParams.append('BillPhoto', billPhoto as any);
            }
                if (framePhotos) {
                framePhotos.forEach((element) => {
                    localVarFormParams.append('FramePhotos', element as any);
                })
            }

                if (serialPhotos) {
                serialPhotos.forEach((element) => {
                    localVarFormParams.append('SerialPhotos', element as any);
                })
            }

    
            if (isCancelled !== undefined) { 
                localVarFormParams.append('IsCancelled', isCancelled as any);
            }
    
            if (userProductId !== undefined) { 
                localVarFormParams.append('UserProductId', userProductId as any);
            }
    
            if (batteryNumber !== undefined) { 
                localVarFormParams.append('BatteryNumber', batteryNumber as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (bikeType !== undefined) { 
                localVarFormParams.append('BikeType', bikeType as any);
            }
    
            if (photoUrl !== undefined) { 
                localVarFormParams.append('PhotoUrl', photoUrl as any);
            }
    
            if (caseId !== undefined) { 
                localVarFormParams.append('CaseId', caseId as any);
            }
    
            if (isHaveOpenCase !== undefined) { 
                localVarFormParams.append('IsHaveOpenCase', isHaveOpenCase as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsUserProductsCasePostPost: async (body?: UserProductPostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userProducts/UserProductsCasePost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProductApi - functional programming interface
 * @export
 */
export const UserProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdateProductType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdateProductType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductByIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductPostPut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves list brand, category, product, repairshop select in order to create new user product
         * @param {boolean} [isGetHide] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsBrandCategoryProductSelectGet(isGetHide?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductBrandCategoryProductSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsBrandCategoryProductSelectGet(isGetHide, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsByUserProductIdDelete(userProductId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsByUserProductIdDelete(userProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existed user product
         * @param {number} userProductId userProductId
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsByUserProductIdPut(userProductId: number, body?: UserProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsByUserProductIdPut(userProductId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsDetailsByUserProductIdGet(userProductId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsDetailsByUserProductIdGet(userProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId detailsUpdate
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsDetailsUpdateByUserProductIdGet(userProductId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductDetailsUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsDetailsUpdateByUserProductIdGet(userProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves  user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsMyUserProductsGet(body?: FilterCaseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductPostPutPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsMyUserProductsGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsMyUserProductsSearch(body?: FilterCaseModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductPostPutPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsMyUserProductsSearch(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsPost(body?: UserProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductOverView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productName 
         * @param {number} productBrandId 
         * @param {number} productCategoryId 
         * @param {number} productWarrantyPeriod 
         * @param {number} [customerId] 
         * @param {number} [productId] 
         * @param {number} [productProductId] 
         * @param {number} [productBrandBrandId] 
         * @param {string} [productBrandName] 
         * @param {boolean} [productBrandIsActive] 
         * @param {string} [productPhoto] 
         * @param {string} [productItemNumber] 
         * @param {number} [productType] 
         * @param {number} [productYear] 
         * @param {boolean} [productHidding] 
         * @param {number} [categoryId] 
         * @param {string} [frameNumber] 
         * @param {string} [serialNumber] 
         * @param {number} [billDateTick] 
         * @param {string} [billNumber] 
         * @param {number} [purchasedPlaceId] 
         * @param {any} [billPhoto] 
         * @param {Array<any>} [framePhotos] 
         * @param {Array<any>} [serialPhotos] 
         * @param {boolean} [isCancelled] 
         * @param {number} [userProductId] 
         * @param {string} [batteryNumber] 
         * @param {number} [type] 
         * @param {number} [bikeType] 
         * @param {string} [photoUrl] 
         * @param {number} [caseId] 
         * @param {boolean} [isHaveOpenCase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsPostWithForm(productName: string, productBrandId: number, productCategoryId: number, productWarrantyPeriod: number, customerId?: number, productId?: number, productProductId?: number, productBrandBrandId?: number, productBrandName?: string, productBrandIsActive?: boolean, productPhoto?: string, productItemNumber?: string, productType?: number, productYear?: number, productHidding?: boolean, categoryId?: number, frameNumber?: string, serialNumber?: string, billDateTick?: number, billNumber?: string, purchasedPlaceId?: number, billPhoto?: any, framePhotos?: Array<any>, serialPhotos?: Array<any>, isCancelled?: boolean, userProductId?: number, batteryNumber?: string, type?: number, bikeType?: number, photoUrl?: string, caseId?: number, isHaveOpenCase?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductOverView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsPostWithForm(productName, productBrandId, productCategoryId, productWarrantyPeriod, customerId, productId, productProductId, productBrandBrandId, productBrandName, productBrandIsActive, productPhoto, productItemNumber, productType, productYear, productHidding, categoryId, frameNumber, serialNumber, billDateTick, billNumber, purchasedPlaceId, billPhoto, framePhotos, serialPhotos, isCancelled, userProductId, batteryNumber, type, bikeType, photoUrl, caseId, isHaveOpenCase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserProductsUserProductsCasePostPost(body?: UserProductPostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductOverView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserProductsUserProductsCasePostPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProductApi - factory interface
 * @export
 */
export const UserProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProductApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateProductType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdateProductType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductByIdGet(id: number, options?: any): AxiosPromise<UserProductPostPut> {
            return localVarFp.apiUserProductByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves list brand, category, product, repairshop select in order to create new user product
         * @param {boolean} [isGetHide] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsBrandCategoryProductSelectGet(isGetHide?: boolean, options?: any): AxiosPromise<UserProductBrandCategoryProductSelect> {
            return localVarFp.apiUserProductsBrandCategoryProductSelectGet(isGetHide, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsByUserProductIdDelete(userProductId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserProductsByUserProductIdDelete(userProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existed user product
         * @param {number} userProductId userProductId
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsByUserProductIdPut(userProductId: number, body?: UserProductPostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserProductsByUserProductIdPut(userProductId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsDetailsByUserProductIdGet(userProductId: number, options?: any): AxiosPromise<UserProductDetails> {
            return localVarFp.apiUserProductsDetailsByUserProductIdGet(userProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a specific user product by userProductId detailsUpdate
         * @param {number} userProductId userProductId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsDetailsUpdateByUserProductIdGet(userProductId: number, options?: any): AxiosPromise<UserProductDetailsUpdate> {
            return localVarFp.apiUserProductsDetailsUpdateByUserProductIdGet(userProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves  user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsMyUserProductsGet(body?: FilterCaseModel, options?: any): AxiosPromise<UserProductPostPutPagingResult> {
            return localVarFp.apiUserProductsMyUserProductsGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search user products of current user(customer)
         * @param {FilterCaseModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsMyUserProductsSearch(body?: FilterCaseModel, options?: any): AxiosPromise<UserProductPostPutPagingResult> {
            return localVarFp.apiUserProductsMyUserProductsSearch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsPost(body?: UserProductPostPut, options?: any): AxiosPromise<UserProductOverView> {
            return localVarFp.apiUserProductsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productName 
         * @param {number} productBrandId 
         * @param {number} productCategoryId 
         * @param {number} productWarrantyPeriod 
         * @param {number} [customerId] 
         * @param {number} [productId] 
         * @param {number} [productProductId] 
         * @param {number} [productBrandBrandId] 
         * @param {string} [productBrandName] 
         * @param {boolean} [productBrandIsActive] 
         * @param {string} [productPhoto] 
         * @param {string} [productItemNumber] 
         * @param {number} [productType] 
         * @param {number} [productYear] 
         * @param {boolean} [productHidding] 
         * @param {number} [categoryId] 
         * @param {string} [frameNumber] 
         * @param {string} [serialNumber] 
         * @param {number} [billDateTick] 
         * @param {string} [billNumber] 
         * @param {number} [purchasedPlaceId] 
         * @param {any} [billPhoto] 
         * @param {Array<any>} [framePhotos] 
         * @param {Array<any>} [serialPhotos] 
         * @param {boolean} [isCancelled] 
         * @param {number} [userProductId] 
         * @param {string} [batteryNumber] 
         * @param {number} [type] 
         * @param {number} [bikeType] 
         * @param {string} [photoUrl] 
         * @param {number} [caseId] 
         * @param {boolean} [isHaveOpenCase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsPostWithForm(productName: string, productBrandId: number, productCategoryId: number, productWarrantyPeriod: number, customerId?: number, productId?: number, productProductId?: number, productBrandBrandId?: number, productBrandName?: string, productBrandIsActive?: boolean, productPhoto?: string, productItemNumber?: string, productType?: number, productYear?: number, productHidding?: boolean, categoryId?: number, frameNumber?: string, serialNumber?: string, billDateTick?: number, billNumber?: string, purchasedPlaceId?: number, billPhoto?: any, framePhotos?: Array<any>, serialPhotos?: Array<any>, isCancelled?: boolean, userProductId?: number, batteryNumber?: string, type?: number, bikeType?: number, photoUrl?: string, caseId?: number, isHaveOpenCase?: boolean, options?: any): AxiosPromise<UserProductOverView> {
            return localVarFp.apiUserProductsPostWithForm(productName, productBrandId, productCategoryId, productWarrantyPeriod, customerId, productId, productProductId, productBrandBrandId, productBrandName, productBrandIsActive, productPhoto, productItemNumber, productType, productYear, productHidding, categoryId, frameNumber, serialNumber, billDateTick, billNumber, purchasedPlaceId, billPhoto, framePhotos, serialPhotos, isCancelled, userProductId, batteryNumber, type, bikeType, photoUrl, caseId, isHaveOpenCase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new user product
         * @param {UserProductPostPut} [body] user product info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserProductsUserProductsCasePostPost(body?: UserProductPostPut, options?: any): AxiosPromise<UserProductOverView> {
            return localVarFp.apiUserProductsUserProductsCasePostPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProductApi - object-oriented interface
 * @export
 * @class UserProductApi
 * @extends {BaseAPI}
 */
export class UserProductApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUpdateProductType(id: number, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUpdateProductType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductByIdGet(id: number, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductByIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves list brand, category, product, repairshop select in order to create new user product
     * @param {boolean} [isGetHide] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsBrandCategoryProductSelectGet(isGetHide?: boolean, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsBrandCategoryProductSelectGet(isGetHide, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific user product by userProductId
     * @param {number} userProductId userProductId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsByUserProductIdDelete(userProductId: number, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsByUserProductIdDelete(userProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existed user product
     * @param {number} userProductId userProductId
     * @param {UserProductPostPut} [body] user product info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsByUserProductIdPut(userProductId: number, body?: UserProductPostPut, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsByUserProductIdPut(userProductId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a specific user product by userProductId
     * @param {number} userProductId userProductId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsDetailsByUserProductIdGet(userProductId: number, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsDetailsByUserProductIdGet(userProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a specific user product by userProductId detailsUpdate
     * @param {number} userProductId userProductId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsDetailsUpdateByUserProductIdGet(userProductId: number, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsDetailsUpdateByUserProductIdGet(userProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves  user products of current user(customer)
     * @param {FilterCaseModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsMyUserProductsGet(body?: FilterCaseModel, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsMyUserProductsGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search user products of current user(customer)
     * @param {FilterCaseModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsMyUserProductsSearch(body?: FilterCaseModel, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsMyUserProductsSearch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new user product
     * @param {UserProductPostPut} [body] user product info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsPost(body?: UserProductPostPut, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productName 
     * @param {number} productBrandId 
     * @param {number} productCategoryId 
     * @param {number} productWarrantyPeriod 
     * @param {number} [customerId] 
     * @param {number} [productId] 
     * @param {number} [productProductId] 
     * @param {number} [productBrandBrandId] 
     * @param {string} [productBrandName] 
     * @param {boolean} [productBrandIsActive] 
     * @param {string} [productPhoto] 
     * @param {string} [productItemNumber] 
     * @param {number} [productType] 
     * @param {number} [productYear] 
     * @param {boolean} [productHidding] 
     * @param {number} [categoryId] 
     * @param {string} [frameNumber] 
     * @param {string} [serialNumber] 
     * @param {number} [billDateTick] 
     * @param {string} [billNumber] 
     * @param {number} [purchasedPlaceId] 
     * @param {any} [billPhoto] 
     * @param {Array<any>} [framePhotos] 
     * @param {Array<any>} [serialPhotos] 
     * @param {boolean} [isCancelled] 
     * @param {number} [userProductId] 
     * @param {string} [batteryNumber] 
     * @param {number} [type] 
     * @param {number} [bikeType] 
     * @param {string} [photoUrl] 
     * @param {number} [caseId] 
     * @param {boolean} [isHaveOpenCase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsPostWithForm(productName: string, productBrandId: number, productCategoryId: number, productWarrantyPeriod: number, customerId?: number, productId?: number, productProductId?: number, productBrandBrandId?: number, productBrandName?: string, productBrandIsActive?: boolean, productPhoto?: string, productItemNumber?: string, productType?: number, productYear?: number, productHidding?: boolean, categoryId?: number, frameNumber?: string, serialNumber?: string, billDateTick?: number, billNumber?: string, purchasedPlaceId?: number, billPhoto?: any, framePhotos?: Array<any>, serialPhotos?: Array<any>, isCancelled?: boolean, userProductId?: number, batteryNumber?: string, type?: number, bikeType?: number, photoUrl?: string, caseId?: number, isHaveOpenCase?: boolean, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsPostWithForm(productName, productBrandId, productCategoryId, productWarrantyPeriod, customerId, productId, productProductId, productBrandBrandId, productBrandName, productBrandIsActive, productPhoto, productItemNumber, productType, productYear, productHidding, categoryId, frameNumber, serialNumber, billDateTick, billNumber, purchasedPlaceId, billPhoto, framePhotos, serialPhotos, isCancelled, userProductId, batteryNumber, type, bikeType, photoUrl, caseId, isHaveOpenCase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new user product
     * @param {UserProductPostPut} [body] user product info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductApi
     */
    public apiUserProductsUserProductsCasePostPost(body?: UserProductPostPut, options?: AxiosRequestConfig) {
        return UserProductApiFp(this.configuration).apiUserProductsUserProductsCasePostPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
