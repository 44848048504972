import * as React from 'react'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import Icon from '../Icon/Icon'
import { Colors } from '../../theme'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_FULL = 'HH:mm DD/MM/YYYY'
export default function StyledDatePicker(props: DatePickerProps<any, any>) {
  return (
    <DatePicker
      components={{ OpenPickerIcon: () => <Icon name='icon-card' color={Colors.secondary} /> }}
      inputFormat={DATE_FORMAT}
      {...props}
    />
  )
}
