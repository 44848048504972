import {
  CustomerCaseApi,
  CustomerProfile,
  ERepairshopType,
  EServicePlace,
  ErrorTypeApi,
  ServiceProviderCaseApi,
} from '../../services/Swagger/Services'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Storage, { USER_PROFILE } from '../../services/Storage'
export interface Item {
  label: string
  value: string | number
}
export const useAddTicket = () => {
  const { t } = useTranslation()
  const userProductQuery = useQuery(['/api/customerCases/userProductSelect'], async () => {
    const res = await new CustomerCaseApi().apiCustomerCasesUserProductSelectGet()
    return res.data
  })
  const errorsQuery = useQuery(['​/api​/errorTypes'], async () => {
    const res = await new ErrorTypeApi().apiErrorTypesGet(1)
    return res.data
  })
  const repairShopsQuery = useQuery(
    ['/api/serviceProviderCases/repairshopDistanceToCustomer'],
    async () => {
      const user: CustomerProfile = Storage.getItem(USER_PROFILE)
      const res =
        await new ServiceProviderCaseApi().apiServiceProviderCasesRepairshopDistanceToCustomerByCustomerIdGet(
          user.userId ?? '',
        )
      return res.data
    },
  )

  const isOnlineProduct = (userProductId: number) => {
    return (
      (userProductQuery.data ?? []).findIndex(
        (product) =>
          product.userProductId === userProductId && product.type === ERepairshopType.OnlineShop,
      ) > -1
    )
  }
  const getServicePlace = (isOnline: boolean) => {
    if (isOnline) {
      return [
        {
          value: EServicePlace.AtRepairshop,
          label: t(EServicePlace.AtRepairshop),
        },
        {
          value: EServicePlace.AtCustomerSite,
          label: t(EServicePlace.AtCustomerSite),
        },
      ]
    } else {
      return [
        {
          value: EServicePlace.AtRepairshop,
          label: t(EServicePlace.AtRepairshop),
        },
      ]
    }
  }

  return {
    products: userProductQuery.data ?? [],
    errorTypes: errorsQuery.data ?? [],
    repairShops: repairShopsQuery.data ?? [],
    isLoading: userProductQuery.isLoading || errorsQuery.isLoading,
    isOnlineProduct,
    getServicePlace,
  }
}
