/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NotificationListResponse } from '../models';
// @ts-ignore
import { NotificationPost } from '../models';
// @ts-ignore
import { NotificationResponse } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsDeleteMyNotificationsDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/deleteMyNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete  notifications by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsDeleteNotificationByNotificationIdDelete: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiNotificationsDeleteNotificationByNotificationIdDelete', 'notificationId', notificationId)
            const localVarPath = `/api/notifications/deleteNotification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves my notifications by paging and index
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsByPageByLimitGet: async (page: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiNotificationsMyNotificationsByPageByLimitGet', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiNotificationsMyNotificationsByPageByLimitGet', 'limit', limit)
            const localVarPath = `/api/notifications/myNotifications/{page}/{limit}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves my notifications by repairshop, repairshopId=-1 => for all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsByRepairshopGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/myNotificationsByRepairshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/myNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post to create notifications
         * @param {Array<NotificationPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPost: async (body?: Array<NotificationPost>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put to set all notfification to be read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsReadMyNotificationsPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/readMyNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put to set a notfification to be read by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsReadNotificationByNotificationIdPut: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiNotificationsReadNotificationByNotificationIdPut', 'notificationId', notificationId)
            const localVarPath = `/api/notifications/readNotification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSendNotiAppPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/sendNotiApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsUnReadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/unRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update type template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsUpdateDashnishContentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/updateDashnishContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsDeleteMyNotificationsDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsDeleteMyNotificationsDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete  notifications by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves my notifications by paging and index
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsMyNotificationsByPageByLimitGet(page: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsMyNotificationsByPageByLimitGet(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves my notifications by repairshop, repairshopId=-1 => for all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsMyNotificationsByRepairshopGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsMyNotificationsByRepairshopGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsMyNotificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsMyNotificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post to create notifications
         * @param {Array<NotificationPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsPost(body?: Array<NotificationPost>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put to set all notfification to be read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsReadMyNotificationsPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsReadMyNotificationsPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put to set a notfification to be read by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsReadNotificationByNotificationIdPut(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsReadNotificationByNotificationIdPut(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsSendNotiAppPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsSendNotiAppPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsUnReadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsUnReadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update type template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsUpdateDashnishContentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsUpdateDashnishContentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsDeleteMyNotificationsDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsDeleteMyNotificationsDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete  notifications by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves my notifications by paging and index
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsByPageByLimitGet(page: number, limit: number, options?: any): AxiosPromise<NotificationListResponse> {
            return localVarFp.apiNotificationsMyNotificationsByPageByLimitGet(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves my notifications by repairshop, repairshopId=-1 => for all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsByRepairshopGet(options?: any): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.apiNotificationsMyNotificationsByRepairshopGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMyNotificationsGet(options?: any): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.apiNotificationsMyNotificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post to create notifications
         * @param {Array<NotificationPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPost(body?: Array<NotificationPost>, options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put to set all notfification to be read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsReadMyNotificationsPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsReadMyNotificationsPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put to set a notfification to be read by id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsReadNotificationByNotificationIdPut(notificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsReadNotificationByNotificationIdPut(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSendNotiAppPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationsSendNotiAppPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsUnReadGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiNotificationsUnReadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update type template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsUpdateDashnishContentGet(options?: any): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.apiNotificationsUpdateDashnishContentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Delete my notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsDeleteMyNotificationsDelete(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsDeleteMyNotificationsDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete  notifications by id
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsDeleteNotificationByNotificationIdDelete(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves my notifications by paging and index
     * @param {number} page 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsMyNotificationsByPageByLimitGet(page: number, limit: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsMyNotificationsByPageByLimitGet(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves my notifications by repairshop, repairshopId=-1 => for all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsMyNotificationsByRepairshopGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsMyNotificationsByRepairshopGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves my notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsMyNotificationsGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsMyNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post to create notifications
     * @param {Array<NotificationPost>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsPost(body?: Array<NotificationPost>, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put to set all notfification to be read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsReadMyNotificationsPut(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsReadMyNotificationsPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put to set a notfification to be read by id
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsReadNotificationByNotificationIdPut(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsReadNotificationByNotificationIdPut(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsSendNotiAppPost(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsSendNotiAppPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsUnReadGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsUnReadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update type template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationsUpdateDashnishContentGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationsUpdateDashnishContentGet(options).then((request) => request(this.axios, this.basePath));
    }
}
