import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CaseDisplayErrorApi, CustomerProfile } from '../services/Swagger/Services'
import { useState } from 'react'
import Storage, { USER_PROFILE } from '../services/Storage'

const user: CustomerProfile = Storage.getItem(USER_PROFILE)

type Props = {
  caseId: number
  onClose: (yes?: boolean) => void
} & DialogProps

const FixByMechanic = 1
const FixMySelf = 2

export default function ErrorModal({ open, caseId, onClose }: Props) {
  const { t } = useTranslation()
  const [userChoice, setUserChoice] = useState(FixMySelf)
  async function submitChoice() {
    try {
      await new CaseDisplayErrorApi().customerConfirmOption(caseId, userChoice)
    } finally {
      onClose()
    }
  }
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogContent style={{ color: 'black' }}>
        <Typography variant='h3' sx={{ color: 'black', mt: 4, mb: 2 }}>
          {t('messageDisplayErrorCaseTitle', { name: user.fullName })}
        </Typography>
        <Typography>{t('messageDisplayErrorCase')}</Typography>
        <RadioGroup
          sx={{ my: 2 }}
          value={userChoice}
          onChange={(e) => {
            setUserChoice(Number(e.target.value))
          }}
        >
          <FormControlLabel value={FixMySelf} control={<Radio />} label={t('option1')} />
          <FormControlLabel value={FixByMechanic} control={<Radio />} label={t('option2')} />
        </RadioGroup>
        <Typography dangerouslySetInnerHTML={{ __html: t('messageDisplayErrorCaseDesc') }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => submitChoice()} color={'primary'} variant='contained'>
          {t('send')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
