import { createSlice } from '@reduxjs/toolkit'
export interface ConfirmDialogProps {
  visible: boolean
  title?: string
  message: string
  form?: React.ReactElement
  yesText: string
  yesBtnColor: string
  noText?: string
  actionNo?: (param?: any) => void
  actionYes?: (param?: any) => void
  paramYes?: any
  paramNo?: any
}

export const ConfirmDialogSlice = createSlice({
  name: 'Alert',
  initialState: {
    title: '',
    visible: false,
    message: '',
    yesText: '',
    yesBtnColor: '',
    noText: '',
    form: undefined,
    actionNo: undefined,
    actionYes: undefined,
    paramYes: undefined,
    paramNo: undefined,
  },
  reducers: {
    updateModalState: (state: any, newState: any) => {
      return { ...newState.payload }
    },
  },
})
export const { updateModalState } = ConfirmDialogSlice.actions
export default ConfirmDialogSlice.reducer
