import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { CheckoutForm } from '.'
import { CustomerCaseApi } from '../services/Swagger/Services'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '')
export interface PaymentProps {
  amount: number
  currency: any
  purchaseOrderId?: number
  purchaseOrderCode?: string
  clientSecret?: string
  onSuccess?: () => void
}

function PaymentForm({
  amount,
  currency,
  purchaseOrderId,
  purchaseOrderCode,
  onSuccess,
}: PaymentProps) {
  const [clientSecret, setClientSecret] = useState<string>()
  useEffect(() => {
    new CustomerCaseApi()
      .apiCustomerCasesCreatePaymentIntentPost(amount, currency, purchaseOrderId, purchaseOrderCode)
      .then((reponse) => {
        setClientSecret(reponse.data.clientSecret)
      })
  }, [])

  return (
    <>
      {stripePromise && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <CheckoutForm
            purchaseOrderId={purchaseOrderId}
            purchaseOrderCode={purchaseOrderCode}
            amount={amount}
            currency={currency}
            clientSecret={clientSecret}
            onSuccess={onSuccess}
          />
        </Elements>
      )}
    </>
  )
}

export default PaymentForm
