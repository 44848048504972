import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import { Stack, Typography } from '@mui/material'
import Menu from '../Menu/Menu'
import Icon from '../Icon/Icon'
import { useNavigate } from 'react-router-dom'
import Storage, { LOGIN_USER } from '../../services/Storage'
import { LoginResponseDto } from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import { useTranslation } from 'react-i18next'

interface AppBarModel {
  title?: string
}
export default function TAppBar({ title }: AppBarModel) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user: LoginResponseDto = Storage.getItem(LOGIN_USER)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const onLogout = () => {
    Storage.clear()
    navigate('/auth/login')
  }
  return (
    <AppBar color='primary' position='static' className='appBar'>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: 60 }}></Box>
          <Box>
            <Typography variant='h2' className='appBarTitle'>
              {title ? title : user && `${t('welcomeBack')} ${user.name}`}
            </Typography>
          </Box>
          <Box sx={{ width: 60 }}>
            {user && (
              <Stack direction='row' spacing={2} sx={{ justifyContent: 'flex-end' }}>
                <IconButton onClick={() => navigate('/notification')} sx={{ p: 0, mr: 4 }}>
                  <Icon name='icon-bell' color={Colors.black} />
                </IconButton>
                <Box>
                  <IconButton
                    onClick={(event) => setAnchorElUser(event.currentTarget)}
                    sx={{ p: 0 }}
                  >
                    <Icon name='icon-profile-circle' color={Colors.black} />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    anchorEl={anchorElUser}
                    open={Boolean(anchorElUser)}
                    onClose={() => setAnchorElUser(null)}
                  >
                    <MenuItem onClick={() => navigate('/profile', {})}>{t('profile')}</MenuItem>
                    <MenuItem onClick={onLogout}>{t('signOut')}</MenuItem>
                  </Menu>
                </Box>
              </Stack>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
