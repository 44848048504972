/* tslint:disable */
/* eslint-disable */
/**
 * CykelmakkerApi
 * CykelmakkerApi
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorTypeListResponse } from '../models';
// @ts-ignore
import { ErrorTypePostPut } from '../models';
/**
 * ErrorTypeApi - axios parameter creator
 * @export
 */
export const ErrorTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new ErrorType
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesCreateNewPost: async (body?: ErrorTypePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/errorTypes/createNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific error type by ErrorTypeId
         * @param {number} errorTypeId errorTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesDeleteByErrorTypeIdDelete: async (errorTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'errorTypeId' is not null or undefined
            assertParamExists('apiErrorTypesDeleteByErrorTypeIdDelete', 'errorTypeId', errorTypeId)
            const localVarPath = `/api/errorTypes/deleteBy/{errorTypeId}`
                .replace(`{${"errorTypeId"}}`, encodeURIComponent(String(errorTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves error type list
         * @param {number} [activeType] -1: All, 0: Inactive, 1: Active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesGet: async (activeType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/errorTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (activeType !== undefined) {
                localVarQueryParameter['activeType'] = activeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existed error type
         * @param {number} errorTypeId errorTypeId
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesUpdateErrorTypeIdPut: async (errorTypeId: number, body?: ErrorTypePostPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'errorTypeId' is not null or undefined
            assertParamExists('apiErrorTypesUpdateErrorTypeIdPut', 'errorTypeId', errorTypeId)
            const localVarPath = `/api/errorTypes/update/{errorTypeId}`
                .replace(`{${"errorTypeId"}}`, encodeURIComponent(String(errorTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorTypeApi - functional programming interface
 * @export
 */
export const ErrorTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new ErrorType
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErrorTypesCreateNewPost(body?: ErrorTypePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErrorTypesCreateNewPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific error type by ErrorTypeId
         * @param {number} errorTypeId errorTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves error type list
         * @param {number} [activeType] -1: All, 0: Inactive, 1: Active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErrorTypesGet(activeType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorTypeListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErrorTypesGet(activeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existed error type
         * @param {number} errorTypeId errorTypeId
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErrorTypesUpdateErrorTypeIdPut(errorTypeId: number, body?: ErrorTypePostPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErrorTypesUpdateErrorTypeIdPut(errorTypeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorTypeApi - factory interface
 * @export
 */
export const ErrorTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new ErrorType
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesCreateNewPost(body?: ErrorTypePostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiErrorTypesCreateNewPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific error type by ErrorTypeId
         * @param {number} errorTypeId errorTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves error type list
         * @param {number} [activeType] -1: All, 0: Inactive, 1: Active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesGet(activeType?: number, options?: any): AxiosPromise<Array<ErrorTypeListResponse>> {
            return localVarFp.apiErrorTypesGet(activeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existed error type
         * @param {number} errorTypeId errorTypeId
         * @param {ErrorTypePostPut} [body] error type info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErrorTypesUpdateErrorTypeIdPut(errorTypeId: number, body?: ErrorTypePostPut, options?: any): AxiosPromise<void> {
            return localVarFp.apiErrorTypesUpdateErrorTypeIdPut(errorTypeId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorTypeApi - object-oriented interface
 * @export
 * @class ErrorTypeApi
 * @extends {BaseAPI}
 */
export class ErrorTypeApi extends BaseAPI {
    /**
     * 
     * @summary Create new ErrorType
     * @param {ErrorTypePostPut} [body] error type info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorTypeApi
     */
    public apiErrorTypesCreateNewPost(body?: ErrorTypePostPut, options?: AxiosRequestConfig) {
        return ErrorTypeApiFp(this.configuration).apiErrorTypesCreateNewPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific error type by ErrorTypeId
     * @param {number} errorTypeId errorTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorTypeApi
     */
    public apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId: number, options?: AxiosRequestConfig) {
        return ErrorTypeApiFp(this.configuration).apiErrorTypesDeleteByErrorTypeIdDelete(errorTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves error type list
     * @param {number} [activeType] -1: All, 0: Inactive, 1: Active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorTypeApi
     */
    public apiErrorTypesGet(activeType?: number, options?: AxiosRequestConfig) {
        return ErrorTypeApiFp(this.configuration).apiErrorTypesGet(activeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existed error type
     * @param {number} errorTypeId errorTypeId
     * @param {ErrorTypePostPut} [body] error type info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorTypeApi
     */
    public apiErrorTypesUpdateErrorTypeIdPut(errorTypeId: number, body?: ErrorTypePostPut, options?: AxiosRequestConfig) {
        return ErrorTypeApiFp(this.configuration).apiErrorTypesUpdateErrorTypeIdPut(errorTypeId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
