import { createTheme } from '@mui/material/styles'
// A custom theme for this app
const defaultControlHeight = 44
const defaultRowHeight = 40

export const Colors = {
  primary: '#95C11F',
  secondary: '#7b7c7e',
  danger: '#e84d48',
  warning: '#f5a623',
  info: 'rgba(50, 173, 230,.5)',
  success: '#417505',
  black: '#1E1F20',
  white: '#FFFFFF',
  gray100: '#f1f1f1',
  backdropBg: 'rgba(33,33,33,.48)',
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.danger,
      contrastText: Colors.white,
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white,
    },
    info: {
      main: Colors.info,
      contrastText: Colors.white,
    },
    success: {
      main: Colors.success,
      contrastText: Colors.white,
    },
    text: {
      primary: '#FFFFFF',
      secondary: Colors.secondary,
    },
    divider: 'rgba(255, 255, 255, 0.2)',
    background: {
      default: Colors.black,
    },
    grey: {
      '100': Colors.gray100,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontSize: 15,
    fontFamily:
      '"AvenirNextLTPro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    body1: {
      fontSize: 15,
    },
    body2: {
      color: 'rgba(255,255,255,0.5)',
    },
    button: {
      fontWeight: 500,
    },
    h2: {
      color: Colors.black,
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      color: Colors.white,
      fontSize: 21,
      fontWeight: 500,
    },
    h4: {
      color: Colors.white,
      fontSize: 19,
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 13,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 13,
    },
    caption: {
      fontSize: 15,
      color: 'rgba(255,255,255,0.5)',
      lineHeight: '36px',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        sx: {
          minHeight: 16,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        sx: {
          color: 'text.primary',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          '& input::placeholder': {
            color: 'text.secondary',
            opacity: 1,
          },
          '& input:-webkit-autofill': {
            backgroundColor: 'transparent !important',
          },
          '&:not(.Mui-disabled):before': {
            borderBottomWidth: '1px',
            borderBottomColor: 'divider',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomWidth: '1px',
            borderBottomColor: 'divider',
          },
          '&:not(.Mui-disabled):after': {
            borderBottomWidth: '1px',
          },
          '&.noBorder:before': {
            borderBottomWidth: '0px',
          },
          '&.noBorder:hover:not(.Mui-disabled):before': {
            borderBottomWidth: '0px',
          },
          '&.noBorder:not(.Mui-disabled):after': {
            borderBottomWidth: '0px',
          },
          '&.MuiInput-root': {
            minWidth: 'auto',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          '&': {
            height: 'auto',
          },
          '& .MuiOutlinedInput-input:not(.MuiAutocomplete-input)': {
            padding: '0 8px',
            minHeight: defaultControlHeight,
            borderRadius: 0.5,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
            padding: '4px 0 1px !important',
          },
          '& .MuiInputBase-multiline': {
            py: 4,
            px: 2,
          },
          '& .MuiInputBase-multiline .MuiInputBase-input': {
            minHeight: defaultControlHeight * 3,
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: 'primary.main',
          },
          '& .MuiIconButton-root': {
            marginRight: '-7px',
          },
          '&.noBorder:before': {
            borderBottomWidth: '0px',
          },
          '&.noBorder:hover:not(.Mui-disabled):before': {
            borderBottomWidth: '0px',
          },
          '&.noBorder:not(.Mui-disabled):after': {
            borderBottomWidth: '0px',
          },
          '&.MuiInput-root': {
            minWidth: 'auto',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          '&.MuiButton-sizeMedium': {
            height: 56,
            fontSize: 20,
            lineHeight: `${defaultControlHeight}px`,
            fontWeight: 600,
            color: Colors.black,
            textTransform: 'none',
          },
          '& .MuiButton-startIcon': {
            marginLeft: 0,
          },
          '&.MuiButton-sizeSmall': {
            height: 36,
            fontSize: 15,
            lineHeight: '36px',
            fontWeight: 600,
            color: Colors.black,
            textTransform: 'none',
            pt: 2,
          },
          '&.MuiButton-root.Mui-disabled': {
            color: Colors.black,
            backgroundColor: Colors.primary,
            opacity: 0.4,
          },
          '&.MuiButton-outlined': {
            color: Colors.primary,
          },
          '&.MuiButton-outlined:hover': {
            backgroundColor: 'transparent',
          },
        },
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: { textDecoration: 'none', color: '#2f6092' },
      },
    },
    MuiListSubheader: {
      defaultProps: {
        sx: {
          lineHeight: `${defaultControlHeight}px`,
          color: 'text.secondary',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          minWidth: 200,
          '& .MuiSelect-select.MuiOutlinedInput-input': {
            paddingLeft: 2,
            paddingTop: 0,
            paddingBottom: 0,
            height: defaultControlHeight,
            minHeight: defaultControlHeight,
            lineHeight: `${defaultControlHeight}px`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '& .MuiInput-input': {
            paddingTop: '5px',
          },
          '& .MuiTypography-root': {
            lineHeight: 'inherit',
          },
          '& .MuiInput-input:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiSelect-icon': {
            color: Colors.gray100,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          '& .MuiIconButton-root': {
            color: Colors.gray100,
            marginRight: '-4px',
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          color: Colors.black,
          lineHeight: 1.5,
          pt: 2.5,
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          borderColor: 'divider',
          py: 0,
          px: 3,
          height: defaultRowHeight,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          py: '7px',
          px: 2,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          py: '7px',
          px: 2,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          minHeight: defaultRowHeight,
          '& .MuiTabs-indicator': {
            height: '1px',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          minHeight: defaultRowHeight,
          textTransform: 'none',
          py: 1,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& >.MuiBox-root': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            transform: 'none',
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          p: 4,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 4,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          p: 4,
          pt: 0,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          fontSize: 12,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        sx: {
          '& .MuiPaper-root': {
            minWidth: 320,
          },
          '& .MuiBackdrop-root': {
            backgroundColor: Colors.backdropBg,
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        sx: {
          '&.MuiContainer-maxWidthSm': {
            maxWidth: 450,
          },
          '&.MuiContainer-maxWidthMd': {
            maxWidth: 550,
          },
          '&.MuiContainer-maxWidthLg': {
            maxWidth: 720,
          },
        },
      },
    },
  },
})

export default theme
